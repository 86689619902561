import { ColumnItem } from 'components/elements/DragAndDropColumnList/DragAndDropColumnList'
import { ItemDropdown } from 'components/elements/DropdownSelector/DropdownSelector'
import {
	FetchResponse,
	Historical,
	SharedTransfer,
	Transfer,
	TransferFilter,
	TransferPost,
	TransferReceived,
	TransferView
} from 'models/index'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'
export interface TransferShareConfiguration {
	initial: ItemDropdown[]
	after: ItemDropdown[]
}

export const createTransferService = async (transfer: Partial<TransferPost>) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Transfer>>(endpoints.transfers, {
			method: 'POST',
			token,
			bodyReq: transfer
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const updateTransferService = async (id: number, transfer: Partial<TransferPost>) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Transfer>>(`${endpoints.transfers}/${id}`, {
			method: 'PUT',
			token,
			bodyReq: transfer
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getTransfersByDateService = async (date: string) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<TransferView[]>>(
			`${endpoints.calendarDaily}/${date}`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getSharedTransfersByDateService = async (date: string) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<SharedTransfer[]>>(
			`${endpoints.calendarDailyShared}/${date}`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getTransferDetailsByIdService = async (id: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<TransferView>>(
			`${endpoints.transfers}/${id}`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getTransferPdfService = async (id: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<string>>(`${endpoints.transfersPdf}/${id}`, {
			token
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const fetchHistoricalTransfersService = async (filter: TransferFilter) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Historical>>(`${endpoints.historical}`, {
			method: 'POST',
			token,
			bodyReq: filter
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const exportHistoricalXlsTransfersService = async (
	filter: TransferFilter,
	columns: ColumnItem[]
) => {
	const token = getUserToken()
	const columsString = columns.map((column) => column.key)
	try {
		const response = await customFetch<FetchResponse<string>>(`${endpoints.historical}/xls`, {
			method: 'POST',
			token,
			bodyReq: { ...filter, columns: columsString }
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const exportHistoricalPdfTransfersService = async (
	filter: TransferFilter,
	columns: ColumnItem[]
) => {
	const token = getUserToken()
	const columsString = columns.map((column) => column.key)
	try {
		const response = await customFetch<FetchResponse<string>>(`${endpoints.historical}/pdf`, {
			method: 'POST',
			token,
			bodyReq: { ...filter, columns: columsString }
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getTransferSharedByBookingId = async (bookingId: number) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<SharedTransfer[]>>(
			`${endpoints.sharedByTransfer}/${bookingId}`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getTransferSharedByRequestId = async (requestId: number) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<SharedTransfer>>(
			`${endpoints.sharedByTransferRequest}/${requestId}`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const sendTransferSharedRequestService = async (
	bookingId: number,
	receivingCompanyId: number
) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<SharedTransfer>>(`${endpoints.shared}`, {
			method: 'POST',
			token,
			bodyReq: { bookingId, receivingCompanyId }
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getAllTransferSharedRequestService = async () => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<SharedTransfer[]>>(
			`${endpoints.shared}/requests`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getAllTransferShareConfigurationsService = async () => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<TransferShareConfiguration>>(
			`${endpoints.shared}/configuration`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const updateInitialConfigurationService = async (idConfigurationToShow: number[]) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<ItemDropdown[]>>(
			`${endpoints.shared}/configuration/initial`,
			{
				method: 'PATCH',
				token,
				bodyReq: { idConfigurationToShow }
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const updateAfterConfigurationService = async (idConfigurationToShow: number[]) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<ItemDropdown[]>>(
			`${endpoints.shared}/configuration/after`,
			{
				method: 'PATCH',
				token,
				bodyReq: { idConfigurationToShow }
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getTransferReceivedByRequestService = async (id: number) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<TransferReceived>>(
			`${endpoints.shared}/transfer-received/${id}`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const acceptTransferSharedRequestService = async (requestId: number) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<Transfer>>(
			`${endpoints.acceptTransferSharedRequest}`,
			{
				method: 'POST',
				token,
				bodyReq: { requestId }
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const rejectTransferSharedRequestService = async (requestId: number) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<void>>(
			`${endpoints.rejectTransferSharedRequest}`,
			{
				method: 'POST',
				token,
				bodyReq: { requestId }
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const cancelTransferSharedRequestService = async (requestId: number) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<Transfer>>(
			`${endpoints.cancelTransferSharedRequest}`,
			{
				method: 'POST',
				token,
				bodyReq: { requestId }
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const deleteTransferService = async (transferId: number) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<void>>(
			`${endpoints.transfers}/${transferId}`,
			{
				method: 'DELETE',
				token
			}
		)

		if ('error' in response) throw response
	} catch (err) {
		throw err
	}
}

export const sendReminderDriverService = async (transferId: number) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<void>>(
			`${endpoints.transfers}/send-reminder-driver/${transferId}`,
			{
				method: 'POST',
				token
			}
		)

		if ('error' in response) throw response
	} catch (err) {
		throw err
	}
}

export const getTransferPreviousUsedAddresses = async () => {
	const token = getUserToken()

	try {
		const response = await customFetch<
			FetchResponse<(Transfer['originAddress'] | Transfer['destinationAddress'])[]>
		>(`${endpoints.settings}/addresses`, {
			token
		})

		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const getTransferToClipboard = async (transferId: Transfer['id']) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<string>>(
			`${endpoints.transfersClipboard}/${transferId}`,
			{ token }
		)

		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}
