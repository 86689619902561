import Chart, { TooltipItem } from 'chart.js/auto'
import colors from 'constants/colors'
import { StatsResponse } from 'models/charts'
import { useEffect, useRef } from 'react'

interface Props {
	datasetLabel: string
	datasetData: StatsResponse['total'][]
	labels: StatsResponse['name'][]
	borderColor?: string
	backgroundColor?: string
	title?: string
}

const LineChart = ({
	datasetLabel,
	datasetData,
	labels = [],
	borderColor = colors.violetMt,
	backgroundColor = colors.violetMt,
	title
}: Props) => {
	const chartRef = useRef<HTMLCanvasElement | null>(null)

	const chartData = {
		labels,
		datasets: [
			{
				label: datasetLabel,
				data: datasetData,
				borderColor,
				backgroundColor,
				fill: true,
				tension: 0.1
			}
		]
	}

	const chartOptions = {
		responsive: true,
		plugins: {
			title: {
				display: !!title,
				text: title,
				font: {
					size: 18
				},
				padding: {
					top: 10,
					bottom: 20
				},
				align: 'center' as const
			},
			tooltip: {
				enabled: true,
				intersect: false,
				mode: 'nearest' as const,
				position: 'nearest' as const,
				callbacks: {
					label: function (tooltipItem: TooltipItem<'line'>) {
						return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`
					}
				}
			},
			legend: {
				display: false
			},
			datalabels: { display: false }
		},
		scales: {
			x: {
				beginAtZero: true,
				grid: {
					display: false
				}
			},
			y: {
				beginAtZero: true,
				grid: {
					display: false
				}
			}
		}
	}

	useEffect(() => {
		if (chartRef.current) {
			const canvas = chartRef.current
			const chartInstance = new Chart(chartRef.current, {
				type: 'line',
				data: chartData,
				options: chartOptions
			})
			const handleResize = () => {
				if (chartInstance && canvas) {
					canvas.width = canvas.parentElement?.offsetWidth || window.innerWidth
					chartInstance.resize()
				}
			}
			window.addEventListener('resize', handleResize)
			return () => {
				window.removeEventListener('resize', handleResize)
				chartInstance.destroy()
			}
		}
	}, [datasetLabel, datasetData, labels, borderColor, backgroundColor])

	return <canvas ref={chartRef} />
}

export default LineChart
