import colors from 'constants/colors'
import React from 'react'

interface Props {
	width?: number
	height?: number
	fillColor?: string
}

const FunnelIcon: React.FC<Props> = (props) => {
	const { width = 19, height = 19, fillColor = colors.blueMt } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M17.3886 3.55582C17.2722 3.29282 17.082 3.06928 16.841 2.91235C16.6001 2.75543 16.3187 2.67189 16.0311 2.67188H2.96861C2.68108 2.6719 2.39975 2.75544 2.1588 2.91233C1.91785 3.06922 1.72765 3.29271 1.61131 3.55565C1.49497 3.81859 1.4575 4.10966 1.50346 4.39349C1.54941 4.67732 1.67681 4.94169 1.87017 5.15449L1.87908 5.16488L6.82798 10.4463V16.0312C6.82794 16.3 6.90086 16.5637 7.03895 16.7942C7.17704 17.0248 7.37513 17.2135 7.61208 17.3403C7.84903 17.4671 8.11595 17.5271 8.38437 17.5141C8.65279 17.5011 8.91264 17.4154 9.13618 17.2662L11.5112 15.6832C11.7145 15.5475 11.8812 15.3637 11.9964 15.1481C12.1117 14.9326 12.1719 14.6919 12.1717 14.4474V10.4463L17.1199 5.16488L17.1288 5.15449C17.3223 4.94181 17.4498 4.67751 17.4959 4.39371C17.5421 4.10991 17.5048 3.81882 17.3886 3.55582ZM10.792 9.31371C10.5336 9.58856 10.39 9.95177 10.3905 10.329V14.2886L8.60923 15.4761V10.329C8.60976 9.95177 8.46613 9.58856 8.20771 9.31371L3.65365 4.45312H15.3461L10.792 9.31371Z'
				style={{ fill: fillColor }}
			/>
		</svg>
	)
}

export default FunnelIcon
