import colors from 'constants/colors'
import React from 'react'

interface Props {
	width?: number
	height?: number
	fillColor?: string
}

const HamburgerIcon: React.FC<Props> = (props) => {
	const { width = 28, height = 28, fillColor = colors.blueMt } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M24.5 14C24.5 14.2321 24.4078 14.4546 24.2437 14.6187C24.0796 14.7828 23.8571 14.875 23.625 14.875H4.375C4.14294 14.875 3.92038 14.7828 3.75628 14.6187C3.59219 14.4546 3.5 14.2321 3.5 14C3.5 13.7679 3.59219 13.5454 3.75628 13.3813C3.92038 13.2172 4.14294 13.125 4.375 13.125H23.625C23.8571 13.125 24.0796 13.2172 24.2437 13.3813C24.4078 13.5454 24.5 13.7679 24.5 14ZM4.375 7.875H23.625C23.8571 7.875 24.0796 7.78281 24.2437 7.61872C24.4078 7.45462 24.5 7.23206 24.5 7C24.5 6.76794 24.4078 6.54538 24.2437 6.38128C24.0796 6.21719 23.8571 6.125 23.625 6.125H4.375C4.14294 6.125 3.92038 6.21719 3.75628 6.38128C3.59219 6.54538 3.5 6.76794 3.5 7C3.5 7.23206 3.59219 7.45462 3.75628 7.61872C3.92038 7.78281 4.14294 7.875 4.375 7.875ZM23.625 20.125H4.375C4.14294 20.125 3.92038 20.2172 3.75628 20.3813C3.59219 20.5454 3.5 20.7679 3.5 21C3.5 21.2321 3.59219 21.4546 3.75628 21.6187C3.92038 21.7828 4.14294 21.875 4.375 21.875H23.625C23.8571 21.875 24.0796 21.7828 24.2437 21.6187C24.4078 21.4546 24.5 21.2321 24.5 21C24.5 20.7679 24.4078 20.5454 24.2437 20.3813C24.0796 20.2172 23.8571 20.125 23.625 20.125Z'
				fill={fillColor}
			/>
		</svg>
	)
}

export default HamburgerIcon
