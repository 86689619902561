import './SearchItem.scss'

interface Props {
	icon: React.ReactNode
	text: string
	text2?: string
	onClick?: () => void
}

const SearchItem = ({ icon, text, text2, onClick }: Props) => {
	return (
		<div className='search-item-container' onClick={onClick}>
			<div className='search-item-box'>{icon}</div>
			<div className='search-item-text-container'>
				<span className='search-item-text'>{text}</span>
				<span className='search-item-text2'>{text2}</span>
			</div>
		</div>
	)
}

export default SearchItem
