import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import DetailsCard from 'components/elements/DetailsCard/DetailsCard'
import DetailsCardItem from 'components/elements/DetailsCard/DetailsCardItem'
import Loading from 'components/elements/Loading/Loading'
import ProfileImageInput from 'components/elements/ProfileImageInput/ProfileImageInput'
import { MY_ACCOUNT_URL, MY_COMPANY_EDIT_URL } from 'constants/routes'
import useCompany from 'hooks/useCompany'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useConfigStore } from 'stores/useConfigStore'
import './MyCompany.scss'

const MyCompany: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const configStore = useConfigStore()
	const { company, loading, error, loadingLogo, logo } = useCompany()

	const detailsInfo = useMemo(() => {
		if (!company) return []
		const province = configStore.getProvinceByCode(company.province)?.name
		const city = configStore.getMunicipalityByProvinceCodeAndMunicipalityCode(
			company.province,
			company.city
		)?.name

		return [
			{ title: t('settings.my_account.my_company.name_label'), value: company.name },
			{
				title: t('settings.my_account.my_company.nif_label'),
				value: company.document
			},
			{
				title: t('settings.my_account.my_company.document_type'),
				value: company.documentType
			},
			{
				title: t('settings.my_account.my_company.main_email_label'),
				value: company.mainEmail
			},
			{
				title: t('settings.my_account.my_company.website_label'),
				value: company.website
			},
			{
				title: t('settings.my_account.my_company.telephone_label'),
				value: company.phone
			},
			{
				title: t('settings.my_account.my_company.address_label'),
				value: company.address
			},
			{
				title: t('settings.my_account.my_company.province_label'),
				value: province
			},
			{ title: t('settings.my_account.my_company.city_label'), value: city },
			{
				title: t('settings.my_account.my_company.post_code_label'),
				value: company.zipCode
			}
		]
	}, [company])

	if (loading) return <Loading fullscreen />
	if (!company) return
	if (error) return <div>{error}</div>

	return (
		<FormLayout
			title={t('settings.my_account.my_company.title')}
			leftButton={<ButtonBack url={MY_ACCOUNT_URL} />}
			rightButton={
				<ButtonComponent onClick={() => navigate(MY_COMPANY_EDIT_URL)} variant='only-text'>
					{t('general.edit')}
				</ButtonComponent>
			}>
			<div className='my-company'>
				<div className='my-company-profile-image'>
					<ProfileImageInput showIcon={false} image={logo} isLoading={loadingLogo} />
				</div>
				<DetailsCard>
					{detailsInfo.map((item) => (
						<DetailsCardItem key={item.title} title={item.title}>
							{item.value}
						</DetailsCardItem>
					))}
				</DetailsCard>
			</div>
		</FormLayout>
	)
}

export default MyCompany
