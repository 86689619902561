import { ENVIRONMENT } from 'constants/environment'
import { User, UserRoles } from 'models/User'
import { Credentials, getMeService, loginService, skipTourService } from 'services/user'
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

interface State {
	currentUser: User
	logIn: (credentials: Credentials) => Promise<void>
	getMe: (token: string) => Promise<void>
	setMe: (user: User) => Promise<void>
	logOut: () => void
	isAdmin(): boolean
	skipTour: () => void
}

export const useUserStore = create<State>()(
	devtools(
		persist(
			(set, get) => ({
				currentUser: {} as User,
				logIn: async (credentials) => {
					const userFromResponse = await loginService(credentials)

					set(() => ({
						currentUser: userFromResponse
					}))
				},
				getMe: async (token) => {
					const userFromResponse = await getMeService(token)

					set(() => ({
						currentUser: userFromResponse
					}))
				},
				setMe: async (user: User) => {
					set(() => ({
						currentUser: user
					}))
				},
				logOut: () => {
					const domain = window.location.hostname.split('.').slice(-2).join('.')
					document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${domain};`

					set({ currentUser: {} as User })
				},
				isAdmin: () => {
					const currentUser = get().currentUser
					const rolesAdmins = [UserRoles.admin, UserRoles['super-admin']]
					return rolesAdmins.includes(currentUser.userTypeId as UserRoles)
				},
				skipTour: async () => {
					await skipTourService()
					set((state) => ({
						currentUser: {
							...state.currentUser,
							showTutorial: false
						}
					}))
				}
			}),
			{
				name: 'users store',
				storage: createJSONStorage(() => localStorage)
			}
		),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'users store'
		}
	)
)
