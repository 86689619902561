import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useConfigurations from 'hooks/useConfigurations'
import useFomento from 'hooks/useFomento'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { FomentoPost, StepFomentoForm } from 'models/Transfer'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFomentoStore } from 'stores/useFomentoStore'
import { useTransfersStore } from 'stores/useTransfersStore'
import { emptyString } from 'utils/common'
import StepFomentoInputs from '../StepInputs/StepFomentoInputs'

const StepFomento = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { configurationTransfer } = useConfigurations()
	const { setActualFomentoCreation, actualFomentoCreation } = useFomentoStore()
	const {
		getProvinceCode,
		getMunicipalityCode,
		useSetFinishedAt,
		getProvinceByCodeHelper,
		getMunicipalityByProvinceCodeAndMunicipalityCodeHelper
	} = useFomento()
	const { actualTransferCreation } = useTransfersStore()

	const formik = useFormik<StepFomentoForm>({
		initialValues: {
			provinceOriginId: actualFomentoCreation.provinceOrigin
				? +actualFomentoCreation.provinceOrigin
				: null,
			municipalityOriginId: actualFomentoCreation.municipalityOrigin
				? +actualFomentoCreation.municipalityOrigin
				: null,
			provinceDestinationId: actualFomentoCreation.provinceDestination
				? +actualFomentoCreation.provinceDestination
				: null,
			municipalityDestinationId: actualFomentoCreation.provinceDestination
				? +actualFomentoCreation.provinceDestination
				: null,
			provinceDistantId: actualFomentoCreation.municipalityDistant
				? +actualFomentoCreation.municipalityDistant
				: null,
			municipalityDistantId: actualFomentoCreation.provinceDestination
				? +actualFomentoCreation.provinceDestination
				: null,
			destinationFreely: actualFomentoCreation.destinationFreely ?? false,
			addressDistant: actualFomentoCreation.addressDistant ?? emptyString,
			finishedAt: actualFomentoCreation.finishedAt ?? undefined
		},
		onSubmit: (values) => {
			const fomentoForm: FomentoPost = {
				provinceOrigin: getProvinceCode(values.provinceOriginId),
				municipalityOrigin: getMunicipalityCode(values.municipalityOriginId),
				provinceDestination: getProvinceCode(values.provinceDestinationId),
				municipalityDestination: getMunicipalityCode(values.municipalityDestinationId),
				provinceDistant: getProvinceCode(values.provinceDistantId),
				municipalityDistant: getMunicipalityCode(values.municipalityDistantId),
				destinationFreely: values.destinationFreely,
				addressDistant: values.addressDistant,
				finishedAt: values.finishedAt ? values.finishedAt : undefined
			}
			setActualFomentoCreation(fomentoForm)
			navigate(`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.PRICES.toLocaleLowerCase()}`)
		}
	})

	useSetFinishedAt(
		{ date: actualTransferCreation.date!, time: actualTransferCreation.time! },
		formik.setFieldValue
	)

	useEffect(() => {
		const config = configurationTransfer && configurationTransfer![actualTransferCreation.route!]

		if (config) {
			formik.setFieldValue(
				'provinceOriginId',
				actualFomentoCreation.provinceOrigin
					? getProvinceByCodeHelper(actualFomentoCreation.provinceOrigin)
					: config.provinceOriginId ?? formik.values.provinceOriginId
			)
			formik.setFieldValue(
				'municipalityOriginId',
				actualFomentoCreation.municipalityOrigin
					? getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
							actualFomentoCreation.provinceOrigin,
							actualFomentoCreation.municipalityOrigin
					  )
					: config.municipalityOriginId ?? formik.values.municipalityOriginId
			)
			formik.setFieldValue(
				'provinceDestinationId',
				actualFomentoCreation.provinceDestination
					? getProvinceByCodeHelper(actualFomentoCreation.provinceDestination)
					: config.provinceDestinationId ?? formik.values.provinceDestinationId
			)
			formik.setFieldValue(
				'municipalityDestinationId',
				actualFomentoCreation.municipalityDestination
					? getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
							actualFomentoCreation.provinceDestination,
							actualFomentoCreation.municipalityDestination
					  )
					: config.municipalityDestinationId ?? formik.values.municipalityDestinationId
			)
			formik.setFieldValue(
				'provinceDistantId',
				actualFomentoCreation.provinceDistant
					? getProvinceByCodeHelper(actualFomentoCreation.provinceDistant)
					: config.provinceDistantId ?? formik.values.provinceDistantId
			)
			formik.setFieldValue(
				'municipalityDistantId',
				actualFomentoCreation.municipalityDistant
					? getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
							actualFomentoCreation.provinceDistant,
							actualFomentoCreation.municipalityDistant
					  )
					: config.municipalityDistantId ?? formik.values.municipalityDistantId
			)
			formik.setFieldValue(
				'destinationFreely',
				config.destinationFreely ?? formik.values.destinationFreely
			)
			formik.setFieldValue('addressDistant', config.addressDistant ?? formik.values.addressDistant)
		}
	}, [configurationTransfer, actualTransferCreation.route, actualFomentoCreation])

	useEffect(() => {
		if (!actualFomentoCreation.finishedAt) return
		formik.setFieldValue('finishedAt', actualFomentoCreation.finishedAt)
	}, [actualFomentoCreation.finishedAt])

	return (
		<FormTransferLayout onSubmit={() => formik.handleSubmit()} step={4}>
			<FormSectionLayout title={t('transfer.create_steps.step_fomento.h1')} noDivider>
				<StepFomentoInputs formik={formik} />
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default StepFomento
