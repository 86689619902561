import { Filters } from 'components/Daily/DailyFilterModal'
import { RouteTypeName, TransferView } from 'models/Transfer'
import { useEffect, useState } from 'react'

export enum FilterKeys {
	selectedRoutes = 'selectedRoutes',
	selectedDriver = 'selectedDriver',
	selectedProvider = 'selectedProvider'
}

const useDailyFilters = (initialTransfers: TransferView[]) => {
	const [filters, setFilters] = useState<Filters>({
		selectedRoutes: [],
		selectedDriver: undefined,
		selectedProvider: null
	})
	const [isFiltering, setIsFiltering] = useState(false)

	useEffect(() => {
		const anyFilterApplied =
			filters.selectedRoutes.length > 0 ||
			filters.selectedDriver !== undefined ||
			filters.selectedProvider !== null

		setIsFiltering(anyFilterApplied)
	}, [filters])

	const applyFilters = (transfers: TransferView[]) => {
		return transfers.filter((transfer) => {
			let matches = true

			if (filters.selectedRoutes.length > 0) {
				matches = matches && filters.selectedRoutes.includes(transfer.route as RouteTypeName)
			}
			if (filters.selectedDriver !== undefined) {
				matches = matches && transfer.driverId === filters.selectedDriver
			}
			if (filters.selectedProvider !== null) {
				matches = matches && transfer.providerId === filters.selectedProvider
			}

			return matches
		})
	}

	const filteredTransfers = applyFilters(initialTransfers)

	const handleApplyFilters = (newFilters: Filters) => {
		setFilters(newFilters)
	}

	const handleResetFilters = () => {
		setFilters({
			selectedRoutes: [],
			selectedDriver: undefined,
			selectedProvider: null
		})
	}

	const removeFilterFromTag = (filterKey: string, value: keyof Filters | RouteTypeName) => {
		const newFilters = { ...filters }
		if (filterKey === FilterKeys.selectedRoutes) {
			newFilters.selectedRoutes = newFilters.selectedRoutes.filter((route) => route !== value)
		} else if (filterKey === FilterKeys.selectedDriver) {
			newFilters.selectedDriver = undefined
		} else if (filterKey === FilterKeys.selectedProvider) {
			newFilters.selectedProvider = null
		}
		setFilters(newFilters)
	}

	return {
		filters,
		isFiltering,
		filteredTransfers,
		handleApplyFilters,
		handleResetFilters,
		removeFilterFromTag,
		setFilters
	}
}

export default useDailyFilters
