import Party from 'assets/images/party.png'
import { CreateTransferIcon } from 'assets/svgs'
import ActionButton from 'components/elements/ActionButton/ActionButton'
import ButtonComponent from 'components/elements/Button/Button'
import { CREATE_URL, DAILY_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import useCompany from 'hooks/useCompany'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const FirstStepsEnd: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const company = useCompany()

	useEffect(() => {
		if (company) {
			company.clearQuery()
		}
	}, [company])

	return (
		<div className='first-steps'>
			<header className='first-steps-header'>
				<img className='first-steps-image' src={Party} alt='celebration' />
				<h1 className='first-steps-title'>{t('onboarding.first_steps.title_completed')}</h1>
				<p className='body1 first-steps-description'>
					{t('onboarding.first_steps.description_completed')}
				</p>
			</header>
			<div className='first-steps-body'>
				<div className='first-steps-body-completed'>
					<ActionButton
						onClick={() =>
							navigate(`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.ROUTE.toLocaleLowerCase()}`)
						}
						icon={<CreateTransferIcon />}
						mode='dark'>
						{t('onboarding.first_steps.create_first_transfer')}
					</ActionButton>
					<ButtonComponent variant='text' onClick={() => navigate(DAILY_URL)}>
						{t('onboarding.first_steps.go_to_home')}
					</ButtonComponent>
				</div>
			</div>
		</div>
	)
}

export default FirstStepsEnd
