import { MapPinIcon, MapPinLineIcon } from 'assets/svgs'
import Card from 'components/elements/Card/Card'
import HeaderTransferDetails from 'components/elements/HeaderTransferDetails/HeaderTransferDetails'
import { TRANSFER_URL } from 'constants/routes'
import { StatsLastTransfersResponse } from 'models/charts'
import { RouteTypeName } from 'models/Transfer'
import { useNavigate } from 'react-router-dom'
import { useConfigStore } from 'stores/useConfigStore'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'
import { dateToSpanish, timeToSpanish } from 'utils/dateUtils'
import './SmallTransferCard.scss'

interface Props {
	transfer: StatsLastTransfersResponse
}

const SmallTransferCard = (props: Props) => {
	const { transfer } = props
	const navigate = useNavigate()
	const { setPreviousRoute } = usePreviousRouteStore()

	const configState = useConfigStore()

	if (!transfer) return null

	const getRouteColor = (route: keyof typeof RouteTypeName) => {
		return configState.colors[route]
	}

	const redirectToTransferDetails = () => {
		navigate(`${TRANSFER_URL}/${transfer.id}`)
	}

	return (
		<Card
			role='button'
			className='small-transfer-card'
			onClick={() => {
				setPreviousRoute(window.location.pathname)
				redirectToTransferDetails()
			}}
			style={{
				borderColor: getRouteColor(transfer.route)
			}}>
			<HeaderTransferDetails
				dailyTransferId={transfer.id}
				driverId={transfer.driverId}
				driver={transfer.driver}
				date={dateToSpanish(new Date(transfer.date))}
				time={timeToSpanish(transfer.time)}
				backgroundColor={'transparent'}
				isSmallTransfer
			/>

			<main className='small-transfer-card-main'>
				<div className='small-transfer-card-main-item'>
					<div className='small-transfer-card-main-item-wrapper'>
						<MapPinIcon />
						<span className='small-transfer-card-main-item-value'>{transfer.originAddress}</span>
					</div>
				</div>
				<div className='small-transfer-card-main-item'>
					<div className='small-transfer-card-main-item-wrapper'>
						<MapPinLineIcon />
						<span className='small-transfer-card-main-item-value'>
							{transfer.destinationAddress}
						</span>
					</div>
				</div>
			</main>
		</Card>
	)
}

export default SmallTransferCard
