import { CloseIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { emptyString } from 'utils/common'
import { handleGeneralKeyboardEvent } from 'utils/functions'
import './Modal.scss'

type ModalProps = {
	isVisible: boolean
	toggleVisibility: () => void
	children: React.ReactNode
	isFull?: boolean
	customClassNames?: string
	showCloseButton?: boolean
}

export const Modal = (props: Readonly<ModalProps>): JSX.Element | null => {
	const {
		isVisible,
		toggleVisibility,
		children,
		isFull = false,
		customClassNames = emptyString,
		showCloseButton = true
	} = props

	useEffect(() => {
		if (isVisible) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = ''
		}
		return () => {
			document.body.style.overflow = ''
		}
	}, [isVisible])

	const modal: JSX.Element = (
		<>
			<div
				role='button'
				className='backdrop'
				onClick={toggleVisibility}
				onKeyDown={handleGeneralKeyboardEvent(() => toggleVisibility())}
			/>
			<div
				className={`modal ${isFull ? 'modal--100' : emptyString} ${
					customClassNames?.length && customClassNames
				}`}
				aria-modal
				aria-label='Modal Details'
				role='dialog'>
				{showCloseButton && (
					<div className='modal-header'>
						<ButtonComponent variant='only-icon' icon={<CloseIcon />} onClick={toggleVisibility} />
					</div>
				)}
				<div className='modal-content'>{children}</div>
			</div>
		</>
	)

	return isVisible ? ReactDOM.createPortal(modal, document.body) : null
}
