import InputButton from 'components/elements/InputButton/InputButton'
import DropdownSelectorModal from 'components/elements/Modal/DropdownSelectorModal'
import { useModal } from 'hooks/useModal'
import useProvider from 'hooks/useProvider'
import useProviders from 'hooks/useProviders'
import useUser from 'hooks/useUser'
import { UserRoles } from 'models/User'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUserStore } from 'stores/useUserStore'
import { emptyString, firstElement } from 'utils/common'

const id = 'select-provider'

interface Props {
	selected: number | number[] | null
	onSelect: (id: number | number[] | null) => void
	error?: string
	label: string
	isLabelOptional?: boolean
	isMultiple?: boolean
	setFieldRef?: (field: string) => (ref: HTMLInputElement) => void
}

const SelectProviderOptions: React.FC<Props> = (props) => {
	const {
		onSelect,
		selected,
		error,
		label,
		isLabelOptional = false,
		isMultiple = false,
		setFieldRef
	} = props
	const { t } = useTranslation()
	const [modalIsVisible, toggleModalVisibility] = useModal()

	const { providers, loading, error: providersError } = useProviders()
	const { hasAssociations } = useProvider()

	const { currentUser } = useUserStore()
	const { me } = useUser(currentUser.id)

	const titleAndPlaceholder = t('form.select_at', { field: t('transfer.provider') })
	const isMultipleAndArray = isMultiple && Array.isArray(selected)

	const getMultipleValueSelected = () => {
		if (isMultipleAndArray && selected.length) {
			const selectedProviders = selected.map(
				(id) => providers.find((provider) => provider.id === id)?.name
			)
			return selectedProviders.join(', ')
		}
	}

	const getValueSelected = () => {
		if (selected && !Array.isArray(selected)) {
			const provider = providers.find((provider) => provider.id === selected)
			return provider?.name
		}
	}

	const handleClick = (ids: number[]) => {
		const selected = isMultiple ? ids : ids[firstElement]
		onSelect(selected)
		toggleModalVisibility()
	}

	const handleReset = () => {
		onSelect(null)
		toggleModalVisibility()
	}

	const itemsDropdownModal = providers
		.filter((provider) => !provider.isManualInvoice)
		.filter((provider) => {
			if (currentUser.userTypeId === UserRoles.provider) {
				return me?.allowedDrivers.includes(provider.id)
			}

			return provider.isActive || provider.id === selected
		})
		.map((provider) => {
			const hasUser = hasAssociations(provider.id)
			return {
				id: provider.id,
				name: provider.name,
				hasUser,
				isSelected: isMultipleAndArray ? selected?.includes(provider.id) : undefined,
				isDisabled: !provider.isActive && provider.id !== selected // Deshabilitar si no está activo y no está seleccionado
			}
		})

	return (
		<div className={`select-option${error ? ' error' : emptyString}`}>
			<label htmlFor={id}>
				{label}
				{isLabelOptional && <span className='helper-text'> ({t('general.optional')})</span>}
			</label>
			<InputButton
				id={id}
				type='select'
				text={isMultiple ? getMultipleValueSelected() : getValueSelected()}
				loading={loading}
				placeholder={titleAndPlaceholder}
				onClick={toggleModalVisibility}
				error={error}
				disabled={loading}
				ref={setFieldRef ? setFieldRef('providerId') : undefined}
			/>

			<DropdownSelectorModal
				title={titleAndPlaceholder}
				visible={modalIsVisible}
				items={itemsDropdownModal}
				toggleVisibility={toggleModalVisibility}
				handleClick={handleClick}
				handleReset={handleReset}
				isMultiple={isMultiple}
				selected={selected}
			/>
			{error && <span className='input-error-message'>{error}</span>}
			{providersError && <span className='input-error-message'>{providersError}</span>}
		</div>
	)
}

export default SelectProviderOptions
