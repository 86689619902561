import DailyTransferCard from 'components/elements/DailyTransferCard/DailyTransferCard'
import { Fomento } from 'models/Fomento'
import { SharedTransfer, StartEndTransfer, TransferView } from 'models/Transfer'
import { FC } from 'react'
import './TransferList.scss'

interface TransfersListProps {
	transfers: TransferView[]
	fomento?: Fomento[]
	startEndTransfers?: StartEndTransfer[]
	sharedTransfers?: SharedTransfer[]
}

const TransfersList: FC<TransfersListProps> = ({
	transfers,
	fomento,
	startEndTransfers,
	sharedTransfers
}) => {
	return (
		<div className='daily-transfer-cards'>
			{transfers.map((transfer) => {
				const fomentoByTransfer = fomento?.find(
					(f) => f.bookingId === transfer.id && f.plate === transfer.licensePlate
				)
				const startEndTransfersByTransfer = startEndTransfers?.filter(
					(startEnd) => startEnd.bookingId === transfer.id
				)
				const sharedTransfersByTransfer = sharedTransfers?.filter(
					(shared) => shared.bookingId === transfer.id
				)
				return (
					<DailyTransferCard
						key={transfer.id}
						dailyTransfer={transfer}
						fomento={fomentoByTransfer}
						startEndTransfers={startEndTransfersByTransfer}
						sharedTransfers={sharedTransfersByTransfer}
					/>
				)
			})}
		</div>
	)
}

export default TransfersList
