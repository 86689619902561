import {
	CalendarPayload,
	CalendarResponse,
	DoughnutChartPayload,
	LineChartPayload,
	StatsByYearTransfersResponse,
	StatsLastTransfersResponse,
	StatsPendingTransfersResponse,
	StatsResponse
} from 'models/charts'
import { FetchResponse } from 'models/index'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getStatsProvider = async (payload: DoughnutChartPayload) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<StatsResponse[]>>(
			`${endpoints.statisticsProvider}`,
			{
				method: 'POST',
				token,
				bodyReq: payload
			}
		)

		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const getStatsDriver = async (payload: DoughnutChartPayload) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<StatsResponse[]>>(
			`${endpoints.statisticsDriver}`,
			{
				method: 'POST',
				token,
				bodyReq: payload
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getStatsVehicle = async (payload: LineChartPayload) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<StatsResponse[]>>(
			`${endpoints.statisticsVehicle}`,
			{
				method: 'POST',
				token,
				bodyReq: payload
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getStatsCalendar = async (payload: CalendarPayload) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<CalendarResponse[]>>(
			`${endpoints.statisticsCalendar}`,
			{
				method: 'POST',
				token,
				bodyReq: payload
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getStatsPendingTransfers = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<StatsPendingTransfersResponse>>(
			`${endpoints.statisticsPendingTransfers}`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getStatsLastTransfers = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<StatsLastTransfersResponse[]>>(
			`${endpoints.statisticsLastTransfers}`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getStatsTransfersByYear = async (year: string) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<StatsByYearTransfersResponse>>(
			`${endpoints.statisticsTransfersByYear}/${year}`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}
