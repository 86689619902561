import Tooltip from 'components/elements/Tooltip/Tooltip'
import useTransfersShare from 'hooks/useTransfersShare'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from '../Loading/Loading'
import './RequestShareTagTooltip.scss'

const RequestShareTagTooltip: FC = () => {
	const { t } = useTranslation()
	const { allTransferShareConfigurations, loadingAllTransferShareConfigurations } =
		useTransfersShare({})

	if (loadingAllTransferShareConfigurations) return <Loading />

	return (
		<Tooltip title={t('transfer.requests_sent.tooltip_title')}>
			<div className='request-share-tag-tooltip-content'>
				<div className='request-share-tag-tooltip-content-item'>
					<span className='request-share-tag-tooltip-content-item-title body1'>
						{t('transfer.requests_sent.tooltip_initial_data_title')}
					</span>
					<p className='request-share-tag-tooltip-content-item-description'>
						{allTransferShareConfigurations?.initial
							.filter((item) => item.isSelected)
							.map((item) => item.name)
							.sort((a, b) => a.localeCompare(b))
							.join(', ')}
					</p>
				</div>
				<div className='request-share-tag-tooltip-content-item'>
					<span className='request-share-tag-tooltip-content-item-title body1'>
						{t('transfer.requests_sent.tooltip_final_data_title')}
					</span>
					<p className='request-share-tag-tooltip-content-item-description'>
						{allTransferShareConfigurations?.after
							.filter((item) => item.isSelected)
							.map((item) => item.name)
							.sort((a, b) => a.localeCompare(b))
							.join(', ')}
					</p>
				</div>
				<div className='request-share-tag-tooltip-content-item'>
					<span className='request-share-tag-tooltip-content-item-title body1'>
						{t('transfer.requests_sent.tooltip_notifications_title')}
					</span>
					<p className='request-share-tag-tooltip-content-item-description'>
						{t('transfer.requests_sent.tooltip_notifications_description')}
					</p>
				</div>
			</div>
		</Tooltip>
	)
}

export default RequestShareTagTooltip
