import { Eye, EyeClosed } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import Tooltip, { TooltipProps } from 'components/elements/Tooltip/Tooltip'
import {
	CSSProperties,
	ChangeEvent,
	ReactNode,
	RefObject,
	forwardRef,
	useEffect,
	useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import './Input.scss'

interface Props {
	type?: 'text' | 'password' | 'number' | 'email'
	label?: string
	id: string
	className?: string
	styles?: CSSProperties
	placeholder?: string
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void
	valueSelected?: string
	error?: string
	labelHelper?: boolean
	customLabelHelperText?: string
	icon?: ReactNode
	tooltip?: TooltipProps
	onFocus?: () => void
	onBlur?: () => void
	showPasswordStrength?: boolean
	readOnly?: boolean
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const getPasswordStrength = (password: string) => {
	let strength = 0
	if (password.length > 5) strength += 1
	if (password.length > 7) strength += 1
	if (/[A-Z]/.test(password)) strength += 1
	if (/[0-9]/.test(password)) strength += 1
	if (/[^A-Za-z0-9]/.test(password)) strength += 1

	return strength
}

const passwordStrengthLabels = [
	'empty',
	'very-weak',
	'weak',
	'good',
	'strong',
	'very-strong'
] as const

const Input = forwardRef<HTMLElement, Props>((props, ref) => {
	const {
		type = 'text',
		label,
		id,
		className = emptyString,
		styles,
		placeholder,
		valueSelected,
		error = emptyString,
		labelHelper = false,
		customLabelHelperText,
		onChange,
		icon,
		tooltip,
		onFocus,
		onBlur,
		showPasswordStrength = false,
		readOnly,
		onKeyDown
	} = props

	const { t } = useTranslation()
	const [value, setValue] = useState(valueSelected ?? emptyString)
	const [inputType, setInputType] = useState(type)
	const [passwordStrength, setPasswordStrength] = useState(0)

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value)
		onChange?.(e)

		if (type === 'password') {
			const strength = getPasswordStrength(e.target.value)
			setPasswordStrength(strength)
		}
	}

	const togglePasswordVisibility = () => {
		setInputType(inputType === 'password' ? 'text' : 'password')
	}

	useEffect(() => {
		setValue(valueSelected ?? emptyString)
	}, [valueSelected])

	return (
		<div
			className={`input-wrapper${value ? ' filled' : emptyString}
				${error ? ' error' : emptyString}`.trim()}
			style={styles}>
			<label className='input-label' htmlFor={id}>
				{label}
				{tooltip && <Tooltip title={tooltip.title} description={tooltip.description} />}
				{labelHelper && (
					<span className='helper-text'>{customLabelHelperText ?? t('general.optional')}</span>
				)}
			</label>
			<div className='input-container'>
				<input
					ref={ref as RefObject<HTMLInputElement>}
					type={inputType}
					id={id}
					name={id}
					className={`${className}${icon ? ' has-icon' : emptyString}`}
					onChange={handleChange}
					value={value}
					placeholder={placeholder}
					onFocus={onFocus}
					onBlur={onBlur}
					readOnly={readOnly}
					onKeyDown={onKeyDown}
				/>
				{type === 'password' && (
					<ButtonComponent
						variant='only-icon'
						onClick={togglePasswordVisibility}
						icon={inputType === 'password' ? <EyeClosed width={24} height={24} /> : <Eye />}
					/>
				)}
				{icon ?? null}
			</div>
			{type === 'password' && showPasswordStrength && (
				<div className='password-strength'>
					<div className={`strength-bar strength-${passwordStrength}`}></div>
					<label>
						{t(`iam.password_strength_values.${passwordStrengthLabels[passwordStrength]}`)}
					</label>
				</div>
			)}
			{error && <span className='input-error-message'>{error}</span>}
		</div>
	)
})

export default Input
