import { FilterKeys } from 'hooks/useDailyFilters'
import useDrivers from 'hooks/useDrivers'
import useProviders from 'hooks/useProviders'
import { RouteTypeName } from 'models/Transfer'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Filters } from './DailyFilterModal'
import './TransferTags.scss'

interface Props {
	filters: Filters
	removeFilterFromTag: (
		filterKey: keyof typeof FilterKeys,
		value: keyof Filters | RouteTypeName
	) => void
}

const TransferTags: FC<Props> = (props) => {
	const { filters, removeFilterFromTag } = props
	const { t } = useTranslation()
	const { drivers } = useDrivers()
	const { providers } = useProviders()

	return (
		<div className='daily-filter-tags-container'>
			{Object.entries(filters).map(([filterKey, filterValue]) => {
				let filterDisplayValue: string | React.ReactNode = ''

				if (filterKey === 'selectedRoutes' && (filterValue as RouteTypeName[]).length > 0) {
					filterDisplayValue = (filterValue as RouteTypeName[]).map((route, index) => (
						<span
							key={index}
							className='filter-tag'
							onClick={() => removeFilterFromTag(FilterKeys.selectedRoutes, route)}>
							{t(`transfer.route.${route}`)}
							<span className='filter-tag-remove'>X</span>
						</span>
					))
				} else if (filterKey === FilterKeys.selectedDriver && filterValue !== undefined) {
					const driverTransfer = drivers.find((driver) => driver.id === filterValue)
					filterDisplayValue = (
						<span
							className='filter-tag'
							onClick={() => removeFilterFromTag(FilterKeys.selectedDriver, filterValue)}>
							{driverTransfer!.name}
							<span className='filter-tag-remove'>X</span>
						</span>
					)
				} else if (filterKey === FilterKeys.selectedProvider && filterValue !== null) {
					const providerTransfer = providers.find((provider) => provider.id === filterValue)
					filterDisplayValue = (
						<span
							className='filter-tag'
							onClick={() => removeFilterFromTag(FilterKeys.selectedProvider, filterValue)}>
							{providerTransfer!.name}
							<span className='filter-tag-remove'>X</span>
						</span>
					)
				}
				if (filterDisplayValue) {
					return (
						<div className='filter-display-value' key={filterKey}>
							{filterDisplayValue}
						</div>
					)
				}
				return null
			})}
		</div>
	)
}

export default TransferTags
