import ButtonComponent from 'components/elements/Button/Button'
import StatusPage from 'components/elements/StatusPage/StatusPage'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserStore } from 'stores/useUserStore'

const InactiveCompany: FC = () => {
	const { t } = useTranslation()
	const userStore = useUserStore()

	return (
		<StatusPage
			title={t('inactive_page.title')}
			subtitle={t('inactive_page.subtitle')}
			description={t('inactive_page.description')}
			button={
				<ButtonComponent
					variant='text'
					onClick={() => {
						userStore.logOut()
					}}>
					{t('inactive_page.action')}
				</ButtonComponent>
			}
		/>
	)
}
export default InactiveCompany
