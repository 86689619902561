import ButtonComponent from 'components/elements/Button/Button'
import StatusPage from 'components/elements/StatusPage/StatusPage'
import { DAILY_URL } from 'constants/routes'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const NotFound: FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return (
		<StatusPage
			title={t('not_found.title')}
			subtitle={t('not_found.subtitle')}
			description={t('not_found.description')}
			button={
				<ButtonComponent variant='text' onClick={() => navigate(DAILY_URL)}>
					{t('not_found.action')}
				</ButtonComponent>
			}
		/>
	)
}
export default NotFound
