import { SearchResponse, SearchState } from 'models/Search'
import { useEffect, useState } from 'react'
import {
	getRecentSearches,
	removeRecentSearches,
	search,
	updateRecentSearches
} from 'services/search'
import useToast from './useToast'

interface UseGlobalSearchResult {
	results: SearchResponse | null
	loading: boolean
	loadingRecentSearches: boolean
	recentSearches: SearchState[]
	executeSearch: (searchObject: SearchState) => Promise<void>
	addRecentSearch: (recentSearchObject: SearchState) => Promise<void>
	cleanRecentSearches: () => Promise<void>
}

export const useGlobalSearch = (): UseGlobalSearchResult => {
	const [results, setResults] = useState<SearchResponse | null>(null)
	const [loading, setLoading] = useState(false)
	const [loadingRecentSearches, setLoadingRecentSearches] = useState(false)
	const [recentSearches, setRecentSearches] = useState<SearchState[]>([])
	const { showBackendErrorToast } = useToast()

	const loadRecentSearches = async () => {
		try {
			setLoadingRecentSearches(true)
			const recentSearches = await getRecentSearches()
			setRecentSearches(recentSearches)
		} catch (error) {
			showBackendErrorToast(error)
		} finally {
			setLoadingRecentSearches(false)
		}
	}

	useEffect(() => {
		loadRecentSearches()
	}, [])

	const executeSearch = async (searchObject: SearchState) => {
		setLoading(true)
		try {
			const data = await search(searchObject)
			setResults(data)
		} catch (error) {
			showBackendErrorToast(error)
		} finally {
			setLoading(false)
		}
	}

	const addRecentSearch = async (recentSearchObject: SearchState) => {
		await updateRecentSearches(recentSearchObject)
	}

	const cleanRecentSearches = async () => {
		try {
			await removeRecentSearches()
			await loadRecentSearches()
		} catch (error) {
			showBackendErrorToast(error)
		}
	}

	return {
		results,
		loading,
		loadingRecentSearches,
		recentSearches,
		executeSearch,
		addRecentSearch,
		cleanRecentSearches
	}
}
