import Button from 'components/elements/Button/Button'
import Loading from 'components/elements/Loading/Loading'
import StepDetailsInputs from 'components/Transfer/StepInputs/StepDetailsInputs'
import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useConfigurations from 'hooks/useConfigurations'
import { useCreateTransfer } from 'hooks/useCreateTransfer'
import useDrivers from 'hooks/useDrivers'
import useFomento from 'hooks/useFomento'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import useToast from 'hooks/useToast'
import { useTransferDetails } from 'hooks/useTransferDetails'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { Permissions } from 'models/Configurations'
import {
	AllStepFormsWithFomentoForm,
	FomentoInfo,
	FomentoPost,
	RouteTypeName,
	TransferPost
} from 'models/Transfer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useFomentoStore } from 'stores/useFomentoStore'
import { useTransfersStore } from 'stores/useTransfersStore'
import { emptyString } from 'utils/common'
import { dateToSQL } from 'utils/dateUtils'
import * as Yup from 'yup'
import StepAddressesInputs from './StepInputs/StepAddressesInputs'
import StepBookingDateInputs from './StepInputs/StepBookingDateInputs'
import StepClientInputs from './StepInputs/StepClientInputs'
import StepExtrasInputs from './StepInputs/StepExtrasInputs'
import StepFomentoInputs from './StepInputs/StepFomentoInputs'
import StepObservationsInputs from './StepInputs/StepObservationsInputs'
import StepPricesInputs from './StepInputs/StepPricesInputs'
import './Steps/StepRoute.scss'
import './TransferDetails.scss'

type RouteParams = {
	routeTypeName?: RouteTypeName
	transferId?: string
}

const TransferCreateNoSteps = () => {
	const { routeTypeName, transferId } = useParams<RouteParams>()
	const { transfer } = useTransferDetails(+transferId!)
	const [fomentoInfo, setFomentoInfo] = useState<FomentoInfo | null>(null)
	const [loadingFomentoInfo, setLoadingFomentoInfo] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const location = useLocation()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { actualTransferCreation } = useTransfersStore()
	const { createTransfer } = useCreateTransfer()
	const fomentoState = useFomentoStore()
	const { drivers } = useDrivers()
	const { getProvinceCode, getMunicipalityCode, getFomentoInfoById, useSetFinishedAt } =
		useFomento()
	const { isConfigurationActive, configurationTransfer } = useConfigurations()

	const isDuplicateTransfer = location.state ? location.state.isDuplicate : false

	const { showBackendErrorToast } = useToast()

	const formik = useFormik<AllStepFormsWithFomentoForm>({
		initialValues: {
			externalId: transfer ? transfer.externalId : null,
			driverId: transfer ? transfer.driverId : null,
			providerId: transfer ? transfer.providerId : null,
			vehicleId: transfer ? transfer.vehicleId : null,
			passengers: transfer ? transfer.passengers : 1,
			date: transfer ? new Date(transfer.date) : undefined,
			time: transfer ? transfer.time : undefined,
			flight: transfer ? transfer.flight : emptyString,
			originAddress: transfer ? transfer.originAddress : emptyString,
			destinationAddress: transfer ? transfer.destinationAddress : emptyString,
			paymentMethodId: transfer ? transfer.paymentMethodId : null,
			price: transfer ? transfer.price : null,
			commission: transfer ? transfer.commission : null,
			otherCommission: transfer ? transfer.otherCommission : null,
			toll: transfer ? transfer.toll : null,
			isPaid: transfer ? transfer.isPaid : false,
			clientName: transfer ? transfer.clientName : emptyString,
			email: transfer ? transfer.email : emptyString,
			phone: transfer ? transfer.phone : emptyString,
			babySeats: transfer ? transfer.babySeats : 0,
			boosterSeats: transfer ? transfer.boosterSeats : 0,
			maxicosiSeats: transfer ? transfer.maxicosiSeats : 0,
			observations: transfer ? transfer.observations : emptyString,
			privateObservations: transfer ? transfer.observations : emptyString,
			routeTypeId: Number(routeTypeName),

			provinceOriginId: null,
			municipalityOriginId: null,
			provinceDestinationId: null,
			municipalityDestinationId: null,
			provinceDistantId: null,
			municipalityDistantId: null,
			destinationFreely: false,
			addressDistant: emptyString,
			finishedAt: undefined
		},
		validationSchema: Yup.object().shape({
			externalId: Yup.string().nullable(),
			driverId: Yup.number().required(t('errors.required_m', { field: t('transfer.driver') })),
			providerId: Yup.number().required(t('errors.required_m', { field: t('transfer.provider') })),
			vehicleId: Yup.number().nullable(),
			passengers: Yup.number().required(
				t('errors.required_m', { field: t('transfer.passengers') })
			),
			date: Yup.string().required(t('errors.required_m', { field: t('transfer.date') })),
			time: Yup.string().required(t('errors.required_m', { field: t('transfer.time') })),
			originAddress: Yup.string().required(t('errors.required_m', { field: t('transfer.origin') })),
			destinationAddress: Yup.string().required(
				t('errors.required_m', { field: t('transfer.destination') })
			),
			price: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			commission: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			otherComission: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			toll: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			isPaid: Yup.boolean(),
			clientName: Yup.string().required(
				t('errors.required_m', { field: t('transfer.clientName') })
			),
			email: Yup.string().email(t('errors.mail_not_valid')).nullable()
		}),
		onSubmit: async (values) => {
			try {
				setIsSubmitting(true)
				if (!values.driverId || !values.providerId) return
				if (!values.date) return
				const transferPost: Partial<TransferPost> = {
					externalId: values.externalId,
					driverId: values.driverId,
					providerId: values.providerId,
					vehicleId: values.vehicleId ?? undefined,
					passengers: values.passengers,
					date: dateToSQL(values.date),
					time: values.time,
					originAddress: values.originAddress,
					destinationAddress: values.destinationAddress,
					paymentMethodId: values.paymentMethodId,
					price: values.price ? parseFloat(values.price.toString()) : 0,
					commission: values.commission ? parseFloat(values.commission.toString()) : 0,
					otherCommission: values.otherCommission
						? parseFloat(values.otherCommission.toString())
						: 0,
					toll: values.toll ? parseFloat(values.toll.toString()) : 0,
					isPaid: values.isPaid,
					clientName: values.clientName,
					email: values.email,
					phone: values.phone,
					babySeats: values.babySeats,
					boosterSeats: values.boosterSeats,
					maxicosiSeats: values.maxicosiSeats,
					observations: values.observations,
					privateObservations: values.privateObservations,
					route: routeTypeName
				}
				if (actualTransferCreation.route === RouteTypeName.arrival)
					transferPost.flight = values.flight

				const fomentoForm: FomentoPost = {
					provinceOrigin: getProvinceCode(values.provinceOriginId),
					municipalityOrigin: getMunicipalityCode(values.municipalityOriginId),
					provinceDestination: getProvinceCode(values.provinceDestinationId),
					municipalityDestination: getMunicipalityCode(values.municipalityDestinationId),
					provinceDistant: getProvinceCode(values.provinceDistantId),
					municipalityDistant: getMunicipalityCode(values.municipalityDistantId),
					destinationFreely: values.destinationFreely,
					addressDistant: values.addressDistant,
					finishedAt: values.finishedAt ? values.finishedAt : undefined
				}
				const transferCreated = await createTransfer(transferPost)
				const isReadyToFomento = fomentoForm.provinceOrigin && fomentoForm.municipalityDestination
				if (isReadyToFomento && transferCreated.id) {
					fomentoState.createFomento(fomentoForm, transferCreated.id)
				}
				navigate(
					`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.END.toLocaleLowerCase()}/${
						transferCreated.id
					}`
				)
			} catch (error) {
				console.error(error)
				showBackendErrorToast(error)
			} finally {
				setIsSubmitting(false)
			}
		}
	})

	useEffect(() => {
		const DEFAULT_DRIVER = drivers.find((driver) => driver.isPending === true)
		if (DEFAULT_DRIVER && isDuplicateTransfer) {
			formik.setFieldValue('driverId', DEFAULT_DRIVER.id)
		}
	}, [drivers, isDuplicateTransfer])

	useEffect(() => {
		const config = configurationTransfer && configurationTransfer![actualTransferCreation.route!]
		if (config) {
			formik.setValues({
				...formik.values,
				driverId: config.driverId ? +config.driverId : formik.values.driverId,
				providerId: config.providerId ? +config.providerId : formik.values.providerId,
				vehicleId: config.vehicleId ? +config.vehicleId : formik.values.vehicleId,
				passengers: config.passengers ?? formik.values.passengers,
				price: config.price ?? formik.values.price,
				date: config.date ? new Date(config.date) : formik.values.date,
				time: config.time ?? formik.values.time,
				originAddress: config.originAddress ?? formik.values.originAddress,
				destinationAddress: config.destinationAddress ?? formik.values.destinationAddress,

				provinceOriginId: config.provinceOriginId
					? +config.provinceOriginId
					: formik.values.provinceOriginId,
				municipalityOriginId: config.municipalityOriginId
					? +config.municipalityOriginId
					: formik.values.municipalityOriginId,
				provinceDestinationId: config.provinceDestinationId
					? +config.provinceDestinationId
					: formik.values.provinceDestinationId,
				municipalityDestinationId: config.municipalityDestinationId
					? +config.municipalityDestinationId
					: formik.values.municipalityDestinationId,
				finishedAt: config.finishedAt ?? formik.values.finishedAt,
				provinceDistantId: config.provinceDistantId
					? +config.provinceDistantId
					: formik.values.provinceDistantId,
				municipalityDistantId: config.municipalityDistantId
					? +config.municipalityDistantId
					: formik.values.municipalityDistantId,
				destinationFreely: config.destinationFreely ?? formik.values.destinationFreely,
				addressDistant: config.addressDistant ?? formik.values.addressDistant
			})
		}
	}, [configurationTransfer, routeTypeName])

	useEffect(() => {
		const fetchFomentoInfo = async () => {
			if (transfer) {
				setLoadingFomentoInfo(true)
				const fomentoInfo = await getFomentoInfoById(transfer.id)
				setFomentoInfo(fomentoInfo)
				setLoadingFomentoInfo(false)
			}
		}
		fetchFomentoInfo()
	}, [transferId])

	useSetFinishedAt({ date: formik.values.date!, time: formik.values.time! }, formik.setFieldValue)

	const handleSubmit = async () => {
		await formik.submitForm()
		focusFirstError()
	}

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	return (
		<FormTransferLayout
			step={2}
			isDuplicateTransfer={transferId !== undefined}
			footer={
				<Button fullWidth onClick={handleSubmit} disabled={isSubmitting}>
					{t('general.finish')}
				</Button>
			}>
			<FormSectionLayout title={t('transfer.create_steps.step_details.h1')} noDivider>
				<StepDetailsInputs formik={formik} setFieldRef={setFieldRef} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_addresses.h1_v1')} noDivider>
				<StepBookingDateInputs formik={formik} setFieldRef={setFieldRef} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_addresses.h1_v2')} noDivider>
				<StepAddressesInputs
					formik={formik}
					route={actualTransferCreation.route}
					setFieldRef={setFieldRef}
				/>
			</FormSectionLayout>
			{isConfigurationActive(Permissions.NOTIFICATION_FOMENTO) && (
				<FormSectionLayout title={t('transfer.create_steps.step_fomento.h1')} noDivider>
					{loadingFomentoInfo ? (
						<Loading hasText={false} />
					) : (
						<StepFomentoInputs formik={formik} fomentoData={fomentoInfo} />
					)}
				</FormSectionLayout>
			)}
			<FormSectionLayout title={t('transfer.create_steps.step_prices.h1')} noDivider>
				<StepPricesInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_client.h1')}>
				<StepClientInputs formik={formik} setFieldRef={setFieldRef} />
			</FormSectionLayout>
			<FormSectionLayout
				title={`${t('transfer.create_steps.step_extras.h1')}`}
				helper={`${t('general.optional')}`}>
				<StepExtrasInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout
				title={`${t('transfer.create_steps.step_observations.h1')}`}
				helper={`${t('general.optional')}`}>
				<StepObservationsInputs formik={formik} />
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default TransferCreateNoSteps
