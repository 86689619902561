import { useQuery } from '@tanstack/react-query'
import {
	bigVehicleStatsQueryKey,
	byYearTransfersStatsQueryKey,
	calendarStatsQueryKey,
	driverStatsQueryKey,
	lastTransfersStatsQueryKey,
	pendingTransfersStatsQueryKey,
	providerStatsQueryKey,
	smallVehicleStatsQueryKey
} from 'constants/reactQueryKeys'
import { CalendarPayload } from 'models/charts'
import { useState } from 'react'
import {
	getStatsCalendar,
	getStatsDriver,
	getStatsLastTransfers,
	getStatsPendingTransfers,
	getStatsProvider,
	getStatsTransfersByYear,
	getStatsVehicle
} from 'services/charts'

const useCharts = () => {
	const currentYear = new Date().getFullYear().toString()
	const startDate = `${currentYear}-01-01`
	const endDate = `${currentYear}-12-31`

	const [calendarPayload, setCalendarPayload] = useState<CalendarPayload>({
		year: currentYear,
		month: (new Date().getMonth() + 1).toString()
	})

	const {
		data: bigVehicleStats = [],
		isLoading: loadingBigVehicleStats,
		error: bigVehicleStatsError
	} = useQuery({
		queryKey: [bigVehicleStatsQueryKey, currentYear],
		queryFn: () => getStatsVehicle({ year: currentYear, fromPax: 5 }),
		enabled: !!currentYear,
		refetchOnWindowFocus: true,
		staleTime: 0
	})

	const {
		data: smallVehicleStats = [],
		isLoading: loadingSmallVehicleStats,
		error: smallVehicleStatsError
	} = useQuery({
		queryKey: [smallVehicleStatsQueryKey, currentYear],
		queryFn: () => getStatsVehicle({ year: currentYear, fromPax: 1, toPax: 4 }),
		enabled: !!currentYear,
		refetchOnWindowFocus: true,
		staleTime: 0
	})

	const {
		data: providerStats = [],
		isLoading: loadingProviderStats,
		error: providerStatsError
	} = useQuery({
		queryKey: [providerStatsQueryKey, startDate, endDate],
		queryFn: () => getStatsProvider({ startDate, endDate, limit: '5' }),
		enabled: !!startDate && !!endDate,
		refetchOnWindowFocus: true,
		staleTime: 0
	})

	const {
		data: driverStats = [],
		isLoading: loadingDriverStats,
		error: driverStatsError
	} = useQuery({
		queryKey: [driverStatsQueryKey, startDate, endDate],
		queryFn: () => getStatsDriver({ startDate, endDate, limit: '5' }),
		enabled: !!startDate && !!endDate,
		refetchOnWindowFocus: true,
		staleTime: 0
	})

	const {
		data: calendarStats = {},
		isLoading: loadingCalendarStats,
		error: calendarStatsError
	} = useQuery({
		queryKey: [calendarStatsQueryKey, calendarPayload],
		queryFn: () => getStatsCalendar(calendarPayload),
		enabled: !!calendarPayload,
		refetchOnWindowFocus: true,
		staleTime: 0
	})

	const {
		data: pendingTransfersStats = {},
		isLoading: loadingPendingTransfersStats,
		error: pendingTransfersStatsError
	} = useQuery({
		queryKey: [pendingTransfersStatsQueryKey],
		queryFn: () => getStatsPendingTransfers(),
		refetchOnWindowFocus: true,
		staleTime: 0
	})

	const {
		data: lastTransferStats = [],
		isLoading: loadingLastTransferStats,
		error: lastTransfersStatsError
	} = useQuery({
		queryKey: [lastTransfersStatsQueryKey],
		queryFn: () => getStatsLastTransfers(),
		refetchOnWindowFocus: true,
		staleTime: 0
	})

	const {
		data: byYearTransferStats = [],
		isLoading: loadingByYearTransferStats,
		error: byYearTransfersStatsError
	} = useQuery({
		queryKey: [byYearTransfersStatsQueryKey],
		queryFn: () => getStatsTransfersByYear(currentYear),
		refetchOnWindowFocus: true,
		staleTime: 0
	})

	return {
		bigVehicleStats,
		smallVehicleStats,
		providerStats,
		driverStats,
		calendarStats,
		lastTransferStats,
		pendingTransfersStats,
		byYearTransferStats,
		loadingBigVehicleStats,
		loadingSmallVehicleStats,
		loadingProviderStats,
		loadingDriverStats,
		loadingCalendarStats,
		loadingPendingTransfersStats,
		loadingLastTransferStats,
		loadingByYearTransferStats,
		setCalendarPayload,
		bigVehicleStatsError,
		smallVehicleStatsError,
		providerStatsError,
		driverStatsError,
		calendarStatsError,
		pendingTransfersStatsError,
		lastTransfersStatsError,
		byYearTransfersStatsError
	}
}

export default useCharts
