import FomentoCategoryTag from 'components/elements/CategoryTag/FomentoCategoryTag'
import { DAILY_URL } from 'constants/routes'
import { useModal } from 'hooks/useModal'
import { useTransferDetails } from 'hooks/useTransferDetails'
import { Fomento } from 'models/Fomento'
import { TransferPost } from 'models/Transfer'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import ModalFormLayout from '../Modal/ModalFormLayout/ModalFormLayout'
import SelectDriverOptions from '../SelectOptions/SelectDriverOptions'
import './HeaderTransferDetails.scss'

interface Props {
	dailyTransferId?: number
	driver?: string
	driverId?: number
	fomento?: Nullable<Fomento>
	isSmallTransfer?: boolean
	date?: string
	time: string
	backgroundColor: string
}

const HeaderTransferDetails: React.FC<Props> = (props) => {
	const {
		dailyTransferId,
		driver,
		driverId,
		fomento,
		date,
		time,
		backgroundColor,
		isSmallTransfer = false
	} = props

	const params = useParams()
	const { transferId } = useParams()
	const navigate = useNavigate()
	const [isDriverModalVisible, toggleDriverModal] = useModal()
	const { transfer, updateTransfer } = useTransferDetails(
		dailyTransferId ? dailyTransferId : +transferId!
	)

	const { t } = useTranslation()

	const onChangeDriver = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation()
		toggleDriverModal()
	}

	const handleDriverSelect = async (selectedDriverId?: number | number[]) => {
		if (!selectedDriverId || !transfer?.id) return

		const transferPost: Partial<TransferPost> = { driverId: selectedDriverId as number }
		await updateTransfer({ transfer: transferPost, id: transfer.id })

		navigate(`${DAILY_URL}/${params.date}`)
		toggleDriverModal()
	}

	return (
		<header
			className={`transfer-details-arrival-header ${isSmallTransfer ? 'small-transfer' : ''}`}
			style={{ backgroundColor }}>
			{driver && (
				<div className='transfer-details-arrival-header-item driver'>
					<span className='body2'>{t('transfer.driver')}</span>
					<div
						className='transfer-details-arrival-header-item-value'
						onClick={onChangeDriver}
						style={{ cursor: 'text' }}>
						{driver}
					</div>
				</div>
			)}
			{}
			{!isSmallTransfer && fomento !== null && (
				<div className='transfer-details-arrival-header-item'>
					<span className='body2'>{t('transfer.status')}</span>
					<FomentoCategoryTag fomento={fomento} />
				</div>
			)}
			{date && (
				<div className='transfer-details-arrival-header-item'>
					<span className='body2'>{t('transfer.date')}</span>
					<div className='transfer-details-arrival-header-item-value'>{date}</div>
				</div>
			)}
			<div className='transfer-details-arrival-header-item'>
				<span className='body2'>{t('transfer.time')}</span>
				<div className='transfer-details-arrival-header-item-value'>{time}</div>
			</div>
			{dailyTransferId && driver && driverId && (
				<ModalFormLayout
					title={t('transfer.driver')}
					isVisible={isDriverModalVisible}
					toggleVisibility={toggleDriverModal}>
					<div className='transfer-details-select-driver-container'>
						<SelectDriverOptions
							onSelect={handleDriverSelect}
							label={t('transfer.driver')}
							selectedId={driver ? driverId : undefined}
						/>
					</div>
				</ModalFormLayout>
			)}
		</header>
	)
}

export default HeaderTransferDetails
