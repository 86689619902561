import { ItemDropdown } from 'components/elements/DropdownSelector/DropdownSelector'
import InputButton from 'components/elements/InputButton/InputButton'
import DropdownSelectorModal from 'components/elements/Modal/DropdownSelectorModal'
import { useModal } from 'hooks/useModal'
import { RouteTypeName } from 'models/Transfer'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfigStore } from 'stores/useConfigStore'
import { emptyString, firstElement } from 'utils/common'
import './SelectOptions.scss'

const id = 'select-route'

interface Props {
	onSelect: (id: number | null) => void
	selectedId?: number | null
	error?: string
	label: string
	isLabelOptional?: boolean
}

const SelectRouteOptions: React.FC<Props> = (props) => {
	const { onSelect, selectedId, error, label, isLabelOptional = false } = props
	const { t } = useTranslation()
	const { routeTypes } = useConfigStore()
	const [items, setItems] = useState<ItemDropdown[]>([])
	const [modalIsVisible, toggleModalVisibility] = useModal()

	const handleClick = (ids: number[]) => {
		onSelect(ids[firstElement])
		toggleModalVisibility()
	}

	const handleReset = () => {
		onSelect(null)
		toggleModalVisibility()
	}

	const getRouteTranslation = (route?: keyof typeof RouteTypeName) => {
		if (!route) return emptyString
		return t(`transfer.route.${route}`)
	}

	const placeholder = selectedId
		? getRouteTranslation(routeTypes.byId[selectedId]?.name) ?? t('general.unknown')
		: t('form.select_at', { field: t('transfer.create_steps.step_route.h1') })

	const valueSelected = selectedId
		? getRouteTranslation(routeTypes.byId[selectedId]?.name)
		: undefined

	useEffect(() => {
		const itemsDropdownModal = routeTypes.allIds.map((id) => {
			const routeType = routeTypes.byId[id]
			return { id: routeType.id, name: getRouteTranslation(routeType.name) }
		})
		setItems(itemsDropdownModal)
	}, [routeTypes])

	return (
		<div className='select-option'>
			<label htmlFor={id}>
				{label}
				{isLabelOptional && <span className='helper-text'> ({t('general.optional')})</span>}
			</label>
			<InputButton
				id={id}
				type='select'
				placeholder={placeholder}
				onClick={toggleModalVisibility}
				text={valueSelected}
				error={error}
			/>

			<DropdownSelectorModal
				title={t('form.select_at', { field: t('transfer.transfer_type') })}
				visible={modalIsVisible}
				items={items}
				toggleVisibility={toggleModalVisibility}
				handleClick={handleClick}
				handleReset={handleReset}
				selected={selectedId}
			/>

			{error && <span className='input-error-message'>{error}</span>}
		</div>
	)
}

export default SelectRouteOptions
