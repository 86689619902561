import { useQuery } from '@tanstack/react-query'
import { configurationsQueryKey, configurationTransferQueryKey } from 'constants/reactQueryKeys'
import { useEffect, useMemo, useState } from 'react'
import { getConfigurations, getConfigurationsTransfer } from 'services/config'

const useConfigurations = () => {
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)
	const {
		data: configurations,
		isLoading,
		isError: isErrorConfigurations,
		error: errorConfigurations
	} = useQuery({
		queryKey: [configurationsQueryKey],
		queryFn: getConfigurations,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	const {
		data: configurationTransfer,
		isLoading: isLoadingConfigurationTransfer,
		isError: isErrorConfigurationTransfer,
		error: errorConfigurationTransfer
	} = useQuery({
		queryKey: [configurationTransferQueryKey],
		queryFn: getConfigurationsTransfer,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	useEffect(() => {
		if (isErrorConfigurations && errorConfigurations) {
			setErrorMessage('Error loading configurations')
		}
	}, [isErrorConfigurations, errorConfigurations])

	useEffect(() => {
		if (isErrorConfigurationTransfer && errorConfigurationTransfer) {
			setErrorMessage('Error loading configurations transfer')
		}
	}, [isErrorConfigurationTransfer, errorConfigurationTransfer])

	const isConfigurationActive = useMemo(() => {
		return (key: string) => {
			const config = configurations?.find((config) => config.key === key)
			return config?.active || false
		}
	}, [configurations])

	return {
		configurations,
		configurationTransfer: configurationTransfer ?? null,
		loading: isLoading,
		isLoadingConfigurationTransfer,
		error: errorMessage,
		errorConfigurationTransfer,
		isConfigurationActive
	}
}

export default useConfigurations
