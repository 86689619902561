import { FC } from 'react'

interface Props {
	width?: number
	height?: number
	className?: string
}

const MagnifyingGlassIcon: FC<Props> = (props) => {
	const { width = 21, height = 21, className = '' } = props

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 21 21'
			fill='none'
			className={className}>
			<path
				d='M19.0714 17.6795L15.1757 13.7822C16.3438 12.2601 16.8891 10.3506 16.7011 8.44124C16.5131 6.53184 15.6058 4.76544 14.1633 3.50037C12.7209 2.2353 10.8512 1.56629 8.93356 1.62905C7.01596 1.69181 5.19402 2.48164 3.83734 3.83832C2.48066 5.19499 1.69083 7.01693 1.62807 8.93454C1.56531 10.8521 2.23432 12.7218 3.49939 14.1643C4.76446 15.6068 6.53086 16.5141 8.44026 16.7021C10.3497 16.8901 12.2591 16.3448 13.7812 15.1767L17.6801 19.0765C17.7717 19.168 17.8804 19.2407 18.0001 19.2902C18.1197 19.3398 18.2479 19.3653 18.3774 19.3653C18.5069 19.3653 18.6351 19.3398 18.7548 19.2902C18.8744 19.2407 18.9831 19.168 19.0747 19.0765C19.1662 18.9849 19.2389 18.8762 19.2884 18.7566C19.338 18.6369 19.3635 18.5087 19.3635 18.3792C19.3635 18.2497 19.338 18.1215 19.2884 18.0018C19.2389 17.8822 19.1662 17.7735 19.0747 17.6819L19.0714 17.6795ZM3.60932 9.18843C3.60932 8.08518 3.93648 7.0067 4.54941 6.08939C5.16234 5.17207 6.03352 4.4571 7.05279 4.03491C8.07206 3.61271 9.19364 3.50225 10.2757 3.71748C11.3577 3.93272 12.3517 4.46398 13.1318 5.2441C13.9119 6.02421 14.4432 7.01814 14.6584 8.10019C14.8736 9.18224 14.7632 10.3038 14.341 11.3231C13.9188 12.3424 13.2038 13.2135 12.2865 13.8265C11.3692 14.4394 10.2907 14.7666 9.18745 14.7666C7.7085 14.765 6.29057 14.1768 5.2448 13.1311C4.19903 12.0853 3.61084 10.6674 3.60932 9.18843Z'
				fill='#343330'
			/>
		</svg>
	)
}

export default MagnifyingGlassIcon
