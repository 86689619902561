export const driversQueryKey = 'drivers'
export const driverQueryKey = 'driver'
export const driverAssociationsQueryKey = 'driverAssociations'
export const providersQueryKey = 'providers'
export const providerQueryKey = 'provider'
export const providerAssociationsQueryKey = 'providerAssociations'
export const usersQueryKey = 'users'
export const userQueryKey = 'user'
export const vehiclesQueryKey = 'vehicles'
export const vehicleQueryKey = 'vehicle'
export const dailyTransfersQueryKey = 'dailyTransfers'
export const transferQueryKey = 'transfer'
export const transfersQueryKey = 'transfers'
export const fomentoQueryKey = 'fomento'
export const sharedTransfersQueryKey = 'sharedTransfers'
export const startEndTransfersQueryKey = 'startEndTransfers'
export const companyQueryKey = 'company'
export const companyActiveQueryKey = 'companyActive'
export const companyLogoQueryKey = 'companyLogo'
export const friendsQueryKey = 'friends'
export const requestsSentQueryKey = 'requestsSent'
export const requestsReceivedQueryKey = 'requestsReceived'
export const friendsAvailableQueryKey = 'friendsAvailable'
export const notificationsQueryKey = 'notifications'
export const unReadNotificationsQueryKey = 'unReadNotifications'
export const configurationsQueryKey = 'configurations'
export const shareTransferByRequestIdQueryKey = 'shareTransferByRequestId'
export const transferReceivedByRequestQueryKey = 'transferReceivedByRequest'
export const allTransfersSharedRequestsQueryKey = 'allTransfersSharedRequests'
export const allTransferShareConfigurationsQueryKey = 'allTransferShareConfigurations'
export const configurationTransferQueryKey = 'configurationTransfer'
export const bigVehicleStatsQueryKey = 'bigVehicleStats'
export const smallVehicleStatsQueryKey = 'smallVehicleStats'
export const providerStatsQueryKey = 'providerStats'
export const driverStatsQueryKey = 'driverStats'
export const calendarStatsQueryKey = 'calendarStats'
export const pendingTransfersStatsQueryKey = 'pendingTransfersStats'
export const lastTransfersStatsQueryKey = 'lastTransfersStats'
export const byYearTransfersStatsQueryKey = 'byYearTransfersStats'
