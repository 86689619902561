import { MagnifyingGlassIcon } from 'assets/svgs'
import { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import './Search.scss'

interface Props {
	onChange: (event: ChangeEvent<HTMLInputElement>) => void
	placeholder?: string
	inputRef?: React.RefObject<HTMLInputElement>
	value?: string
}

const Search: FC<Props> = (props) => {
	const { onChange, placeholder, inputRef = null, value } = props
	const { t } = useTranslation()
	const defaultPlaceholder = t('general.search')

	return (
		<div className='search-bar'>
			<MagnifyingGlassIcon />
			<input
				type='text'
				onChange={onChange}
				placeholder={placeholder || defaultPlaceholder}
				{...(value !== undefined && { value })}
				ref={inputRef}
			/>
		</div>
	)
}

export default Search
