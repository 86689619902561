import { FetchResponse } from 'models/index'
import { RecentSearchResponse, SearchResponse, SearchState } from 'models/Search'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const search = async (search: SearchState) => {
	const token = getUserToken()

	const queryParams = {
		query: search.query,
		tag: search.tag
	}

	try {
		const response = await customFetch<FetchResponse<SearchResponse>>(endpoints.search, {
			method: 'POST',
			token,
			bodyReq: queryParams
		})
		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const getRecentSearches = async (): Promise<SearchState[]> => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<RecentSearchResponse[]>>(
			endpoints.recentSearches,
			{ token }
		)
		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const updateRecentSearches = async (recentSearch: SearchState) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<RecentSearchResponse>>(
			endpoints.saveRecentSearch,
			{
				method: 'POST',
				token,
				bodyReq: recentSearch
			}
		)
		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const removeRecentSearches = async () => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<void>>(endpoints.clearRecentSearches, {
			method: 'DELETE',
			token
		})
		if ('error' in response) throw response
	} catch (err) {
		throw err
	}
}
