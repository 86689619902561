import { FC } from 'react'
import './StatusPage.scss'

interface Props {
	title: string
	subtitle: string
	description: string
	button: React.ReactNode
	icon?: React.ReactNode
}

const StatusPage: FC<Props> = (props) => {
	const { title, subtitle, description, button, icon } = props
	return (
		<div className='status-page'>
			<div className='status-page-container'>
				{icon}
				<h1>{title}</h1>
				<h3>{subtitle}</h3>
				<p className='body1 status-page-description'>{description}</p>
			</div>
			{button}
		</div>
	)
}

export default StatusPage
