import { ErrorResponse } from 'models/CustomFetch'

interface Options<TBody> {
	method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
	bodyReq?: TBody
	params?: Record<string, string | number | boolean>
	token?: string
	headers?: Record<string, string>
	hideHeaders?: boolean
	responseType?: 'json' | 'text'
}

export async function customFetch<TResponse = unknown, TBody = unknown>(
	URL: string,
	options: Options<TBody>
): Promise<TResponse>

export async function customFetch<TResponse = unknown, TBody = unknown>(
	URL: string,
	{
		method = 'GET',
		bodyReq,
		params,
		token,
		headers = {},
		hideHeaders = false,
		responseType = 'json'
	}: Options<TBody>
): Promise<TResponse | ErrorResponse> {
	if (!URL) throw new Error('Invalid URL')

	const queryString = params
		? '?' +
		  new URLSearchParams(
				Object.entries(params).reduce((acc, [key, value]) => {
					acc[key] = String(value)
					return acc
				}, {} as Record<string, string>)
		  ).toString()
		: ''

	const finalURL = `${URL}${queryString}`

	const customHeaders: Record<string, string> = token
		? {
				'Content-Type': 'application/json',
				'Token': token,
				'Accept-Language': getLanguage(),
				...headers
		  }
		: { 'Content-Type': 'application/json', 'Accept-Language': getLanguage(), ...headers }

	const requestOptions: RequestInit = {
		method,
		body: bodyReq ? JSON.stringify(bodyReq) : null,
		headers: !hideHeaders ? customHeaders : undefined
	}

	try {
		const response = await fetch(finalURL, requestOptions)

		// Check if the response was successful (status in the range 200-299)
		if (!response.ok) {
			// Process error response and return as ErrorResponse
			const errorData = (await response.json()) as ErrorResponse
			return {
				status: response.status,
				error: errorData.error || response.status,
				messages: errorData.messages || { general: 'An error occurred' }
			}
		}

		// If the response is successful, parse it based on the responseType
		if (responseType === 'json') {
			return (await response.json()) as TResponse
		} else {
			return (await response.text()) as unknown as TResponse
		}
	} catch (error) {
		// Catch fetch/network errors and return an ErrorResponse
		return (
			handleErrorResponse(error) || {
				status: 500,
				error: 500,
				messages: { general: 'Network error occurred' }
			}
		)
	}
}

export function handleErrorResponse(error: unknown): ErrorResponse | null {
	if (typeof error === 'object' && error !== null) {
		const potentialErrorResponse = error as Partial<ErrorResponse>
		if (
			'messages' in potentialErrorResponse &&
			'status' in potentialErrorResponse &&
			'error' in potentialErrorResponse
		) {
			return potentialErrorResponse as ErrorResponse
		}
	}
	return null
}

const getLanguage = (): string => {
	try {
		const languageData = localStorage.getItem('language')
		if (languageData) {
			const parsedData = JSON.parse(languageData)
			return parsedData.state.language.toLowerCase()
		}
	} catch (error) {
		console.error('Error parsing language from localStorage:', error)
	}
	return 'es'
}
