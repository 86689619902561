import StepAddressesInputs from 'components/Transfer/StepInputs/StepAddressesInputs'
import StepBookingDateInputs from 'components/Transfer/StepInputs/StepBookingDateInputs'
import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useConfigurations from 'hooks/useConfigurations'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { Permissions } from 'models/Configurations'
import { RouteTypeName, StepAddressesForm, TransferPost } from 'models/Transfer'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTransfersStore } from 'stores/useTransfersStore'
import { emptyString } from 'utils/common'
import { dateToSQL } from 'utils/dateUtils'
import * as Yup from 'yup'

const StepAddresses = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { configurationTransfer, isConfigurationActive } = useConfigurations()
	const { actualTransferCreation, updateActualTransferCreation } = useTransfersStore()

	const formik = useFormik<StepAddressesForm>({
		initialValues: {
			date: actualTransferCreation.date ? new Date(actualTransferCreation.date!) : undefined,
			time: actualTransferCreation.time ?? undefined,
			flight: actualTransferCreation.flight ?? emptyString,
			originAddress: actualTransferCreation.originAddress ?? emptyString,
			destinationAddress: actualTransferCreation.destinationAddress ?? emptyString
		},
		validationSchema: Yup.object().shape({
			date: Yup.string().required(t('errors.required_m', { field: t('transfer.date') })),
			time: Yup.string().required(t('errors.required_m', { field: t('transfer.time') })),
			originAddress: Yup.string().required(t('errors.required_m', { field: t('transfer.origin') })),
			destinationAddress: Yup.string().required(
				t('errors.required_m', { field: t('transfer.destination') })
			)
		}),
		onSubmit: (values) => {
			if (!values.date) return

			const transferPost: Partial<TransferPost> = {
				date: dateToSQL(values.date),
				time: values.time,
				originAddress: values.originAddress,
				destinationAddress: values.destinationAddress
			}
			if (actualTransferCreation.route === RouteTypeName.arrival)
				transferPost.flight = values.flight

			updateActualTransferCreation(transferPost)
			navigate(
				isConfigurationActive(Permissions.NOTIFICATION_FOMENTO)
					? `${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.FOMENTO.toLocaleLowerCase()}`
					: `${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.PRICES.toLocaleLowerCase()}`
			)
		}
	})

	useEffect(() => {
		const config = configurationTransfer && configurationTransfer![actualTransferCreation.route!]

		if (config) {
			formik.setValues({
				...formik.values,
				date: actualTransferCreation.date
					? new Date(actualTransferCreation.date)
					: config.date
					? new Date(config.date)
					: formik.values.date,
				time: actualTransferCreation.time ?? config.time ?? formik.values.time,
				originAddress:
					actualTransferCreation.originAddress ??
					config.originAddress ??
					formik.values.originAddress,
				destinationAddress:
					actualTransferCreation.destinationAddress ??
					config.destinationAddress ??
					formik.values.destinationAddress
			})
		}
	}, [configurationTransfer, actualTransferCreation.route, actualTransferCreation])

	return (
		<FormTransferLayout onSubmit={() => formik.handleSubmit()} step={3}>
			<FormSectionLayout title={t('transfer.create_steps.step_addresses.h1_v1')} noDivider>
				<StepBookingDateInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_addresses.h1_v2')} noDivider>
				<StepAddressesInputs formik={formik} route={actualTransferCreation.route} />
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default StepAddresses
