import { FetchResponse, Provider, ProviderAssociations, ProviderPost } from 'models/index'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getAllProvidersService = async () => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<Provider[]>>(`${endpoints.providers}`, {
			token
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getProviderById = async (id: number): Promise<Provider> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Provider>>(`${endpoints.providers}/${id}`, {
			token
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const createProviderService = async (provider: ProviderPost) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Provider>>(`${endpoints.providers}`, {
			method: 'POST',
			token,
			bodyReq: provider
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const updateProviderService = async (
	provider: Partial<ProviderPost>,
	providerId: number
) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Provider>>(
			`${endpoints.providers}/${providerId}`,
			{
				method: 'PATCH',
				token,
				bodyReq: provider
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getProviderAssociations = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<ProviderAssociations[]>>(
			`${endpoints.providers}/associations`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const resetAndSetAssociationsToProvider = async (
	providerId: number,
	userIds: number[]
): Promise<ProviderAssociations[]> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<ProviderAssociations[]>>(
			`${endpoints.providers}/associations/reset-set`,
			{
				method: 'POST',
				token,
				bodyReq: { userIds, providerId }
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const deleteAssociationsByProviderId = async (providerId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<void>>(
			`${endpoints.providers}/associations/${providerId}`,
			{
				method: 'DELETE',
				token
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const deleteProviderById = async (providerId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<void>>(
			`${endpoints.providers}/${providerId}`,
			{
				method: 'DELETE',
				token
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}
