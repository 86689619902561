import { ENVIRONMENT } from 'constants/environment'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface PreviousRouteState {
	previousRoute: string | null
	isResettable: boolean
	setPreviousRoute: (route: string, resettable?: boolean) => void
	resetPreviousRoute: () => void
	setResettable: (resettable: boolean) => void
}

export const usePreviousRouteStore = create<PreviousRouteState>()(
	devtools(
		(set) => ({
			previousRoute: null,
			isResettable: true,
			setPreviousRoute: (route, resettable = false) =>
				set({ previousRoute: route, isResettable: resettable }),
			resetPreviousRoute: () => set({ previousRoute: null, isResettable: true }),
			setResettable: (resettable) => set({ isResettable: resettable })
		}),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'previous route store'
		}
	)
)
