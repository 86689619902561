import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
	color?: string
}

const CaretRight: FC<Props> = (props) => {
	const { width = 23, height = 23, color = colors.blueMt } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M16.3209 12.0089L9.13343 19.1964C9.06665 19.2632 8.98737 19.3162 8.90012 19.3523C8.81287 19.3884 8.71935 19.4071 8.62491 19.4071C8.53047 19.4071 8.43696 19.3884 8.3497 19.3523C8.26245 19.3162 8.18317 19.2632 8.1164 19.1964C8.04962 19.1296 7.99664 19.0504 7.9605 18.9631C7.92436 18.8759 7.90576 18.7823 7.90576 18.6879C7.90576 18.5935 7.92436 18.4999 7.9605 18.4127C7.99664 18.3254 8.04962 18.2462 8.1164 18.1794L14.7963 11.5004L8.1164 4.82142C7.98153 4.68655 7.90576 4.50363 7.90576 4.3129C7.90576 4.12217 7.98153 3.93925 8.1164 3.80438C8.25126 3.66952 8.43418 3.59375 8.62491 3.59375C8.81564 3.59375 8.99856 3.66952 9.13343 3.80438L16.3209 10.9919C16.3878 11.0586 16.4408 11.1379 16.4769 11.2252C16.5131 11.3124 16.5317 11.4059 16.5317 11.5004C16.5317 11.5949 16.5131 11.6884 16.4769 11.7756C16.4408 11.8629 16.3878 11.9422 16.3209 12.0089Z'
				fill={color}
			/>
		</svg>
	)
}

export default CaretRight
