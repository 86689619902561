import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { customFetch, handleErrorResponse } from 'services/customFetch'
import { getUserToken } from 'services/utils'

const useFetchPdf = (url: string) => {
	const [pdfBase64, setPdfBase64] = useState<Nullable<string>>(null)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Nullable<string>>(null)

	const { t } = useTranslation()

	useEffect(() => {
		const fetchPdf = async () => {
			setIsLoading(true)
			try {
				const token = getUserToken()
				const { data } = (await customFetch(url, {
					token
				})) as { data: string }
				setPdfBase64(`data:application/pdf;base64,${data}`)
			} catch (error: unknown) {
				const errorResponse = handleErrorResponse(error)
				if (errorResponse) {
					const messagesText = Object.values(errorResponse.messages).join('\n')
					setError(messagesText)
				} else {
					setError(t('errors.unexpected_error'))
				}
			} finally {
				setIsLoading(false)
			}
		}

		fetchPdf()
	}, [url])

	return { pdfBase64, isLoading, error }
}

export default useFetchPdf
