import {
	CarSimpleIcon,
	CheckCircle,
	IdentificationBadgeIcon,
	SteeringWheel,
	UserList
} from 'assets/svgs'
import { ONBOARDING_STEP } from 'components/Onboarding/FirstSteps/FirstSteps'
import ProgressBar from 'components/elements/ProgressBar/ProgressBar'
import StepNumber, { StepNumberColor } from 'components/elements/StepNumber/StepNumber'
import { useTranslation } from 'react-i18next'
import './FirstStepsCard.scss'

const StepCardStatus = {
	COMPLETED: 'COMPLETED',
	DISABLED: 'DISABLED',
	ACTIVE: 'ACTIVE'
}

const StepCardStatusColor = {
	COMPLETED: StepNumberColor.GREEN,
	DISABLED: StepNumberColor.GRAY,
	ACTIVE: StepNumberColor.VIOLET
}

interface Props {
	step: ONBOARDING_STEP
	title: string
	progress: number
	isDisabled?: boolean
	onClick: () => void
}

const FirstStepsCard: React.FC<Props> = (props) => {
	const { title, progress, isDisabled, onClick } = props

	const { t } = useTranslation()

	const isCompleted = progress === 100

	const stepCardStatus = isDisabled
		? StepCardStatus.DISABLED
		: isCompleted
		? StepCardStatus.COMPLETED
		: StepCardStatus.ACTIVE

	const stepNumberColor = StepCardStatusColor[stepCardStatus as keyof typeof StepCardStatusColor]

	const stepIcon = {
		[ONBOARDING_STEP.FIRST]: {
			header: <StepNumber number={ONBOARDING_STEP.FIRST} color={stepNumberColor} />,
			body: <IdentificationBadgeIcon />
		},
		[ONBOARDING_STEP.SECOND]: {
			header: <StepNumber number={ONBOARDING_STEP.SECOND} color={stepNumberColor} />,
			body: <UserList />
		},
		[ONBOARDING_STEP.THIRD]: {
			header: <StepNumber number={ONBOARDING_STEP.THIRD} color={stepNumberColor} />,
			body: <SteeringWheel />
		},
		[ONBOARDING_STEP.FOURTH]: {
			header: <StepNumber number={ONBOARDING_STEP.FOURTH} color={stepNumberColor} />,
			body: <CarSimpleIcon />
		}
	}

	const classNames = () => {
		let classes = 'first-steps-card'
		if (stepCardStatus) classes += ` ${stepCardStatus.toLocaleLowerCase()}`

		return classes
	}

	return (
		<button
			className={classNames()}
			onClick={stepCardStatus !== StepCardStatus.DISABLED ? onClick : undefined}
			disabled={stepCardStatus === StepCardStatus.DISABLED}>
			<div className='first-steps-card-header'>
				<div className='first-steps-card-header-right'>
					{stepIcon[props.step].header}
					<span className='first-steps-card-header-title'>{title}</span>
				</div>
				{stepCardStatus === StepCardStatus.COMPLETED && <CheckCircle width={24} height={24} />}
			</div>
			{stepCardStatus !== StepCardStatus.COMPLETED && (
				<div className='first-steps-card-body'>
					{stepCardStatus === StepCardStatus.ACTIVE ? (
						<>
							{stepIcon[props.step].body}
							<ProgressBar percentile={progress} variant='green' />
							<span className='first-steps-card-progress-text body2'>
								{t('onboarding.first_steps.progress', { progress })}
							</span>
						</>
					) : (
						<span className='first-steps-card-disabled-text'>
							{t('onboarding.first_steps.disabled_text')}
						</span>
					)}
				</div>
			)}
		</button>
	)
}

export default FirstStepsCard
