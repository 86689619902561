import { Driver } from './Driver'
import { Provider } from './Provider'
import { Transfer } from './Transfer'
import { Vehicle } from './Vehicle'

export enum Permissions {
	ALLOW_CREATE_TRANSFER = 'allow_create_transfer',
	ALLOW_LOCATION = 'allow_location',
	ALWAYS_SHOW_FAR = 'always_show_lejano',
	CANCEL_FOMENTO = 'anula_fomento',
	OWN_CERTIFICATE = 'certificado_propio',
	CHECK_PENDING_DRIVERS_TOMORROW = 'check_if_tomorrow_conduc_pentientes',
	PASS_COMMISSION_TO_OTHER = 'comision_pasada_otro',
	SHARE_WITH_FRIEND = 'compartir_amigo',
	COMMUNICATE_FOMENTO = 'comunica_fomento',
	MULTIPLE_DRIVERS = 'conductores_multiple',
	CREATE_TRANSFER = 'crear_transfer',
	WORK_SCHEDULE = 'cuadrante',
	DUPLICATE_TRANSFER = 'duplicar_transfer',
	EDIT_COMMISSION_PAYMENT = 'editar_comision_pago',
	EDIT_TRANSFER_NEW_DRIVERS = 'editar_transfer_drivers_new',
	SEND_CLIENT_EMAIL = 'envio_mail_cliente',
	BILLING = 'facturacion',
	MANUAL_INVOICES = 'facturas_manuales',
	SIMPLIFIED_INVOICE = 'factura_simplificada',
	SUBTRACT_VAT_UNPAID = 'has_restar_iva_no_pagados',
	HIDE_PROVIDER = 'hide_proveedor',
	GET_HISTORY = 'historico_get',
	HISTORY_WITH_TIME = 'historico_with_time',
	IMPORT_EXCEL = 'import_excel',
	IMPORT_XML = 'import_xml',
	START_END = 'ini_fin',
	MAIL_TRANSFERS_YESTERDAY = 'mail_transfers_ayer',
	NOTIFICATION_FOMENTO = 'notificacion_fomento',
	PRIVATE_NOTES = 'observaciones_privadas',
	ORDER_DRIVERS_BY = 'order_drivers_by',
	DRIVER_PAYMENT = 'pago_chofer',
	FORBIDDEN_DELETE_TRANSFER = 'prohibido_eliminar_transfer',
	MULTIPLE_PROVIDERS = 'proveedores_multiple',
	PUBLISH_BOARD = 'publicar_board',
	REMOVE_CANCEL_FOMENTO = 'remove_anula_fomento',
	SAVE_LOCATION = 'save_location',
	VIEW_BOARD = 'see_board',
	MULTIPLE_SELECTION = 'seleccionMultiple',
	SEALED_KM = 'sellado_km',
	TOW_SERVICES = 'servicios_grua',
	SHOW_COMMISSION_PAYMENTS = 'show_comision_pagos',
	SHOW_DAILY_DRIVER = 'show_conductor_diario',
	SHOW_CONTRACTING_CONTRACTED = 'show_contratante_contratado',
	SHOW_EXTERNAL_ID = 'show_id_externo',
	SHOW_DRIVER_TRANSFER_PRICE = 'show_precio_transfer_chofer',
	SHUTTLE = 'shuttle',
	TAXIMETER = 'taximetro',
	VEHICLE_TYPE = 'tipo_vehiculo',
	VIEW_DRIVER_CONTRACT = 'view_conductor_contrato',
	VIEW_PAID_PRICE = 'view_precio_pagado'
}

export interface Configuration {
	id: number
	companyId: number
	key: Permissions
	active: boolean
}

export interface ConfigurationTransferResponse {
	arrival: CommonConfigurationTransferDetails
	departure: CommonConfigurationTransferDetails
	transfer: CommonConfigurationTransferDetails
	crane: CommonConfigurationTransferDetails
	disposition: CommonConfigurationTransferDetails
}

interface CommonConfigurationTransferDetails {
	vehicleId?: Vehicle['id']
	driverId?: Driver['id']
	providerId?: Provider['id']
	passengers?: Transfer['passengers']
	price?: number
	date?: string
	time?: string
	originAddress?: string
	destinationAddress?: string

	provinceOriginId?: string
	municipalityOriginId?: string
	provinceDestinationId?: string
	municipalityDestinationId?: string
	finishedAt?: string
	provinceDistantId?: string
	municipalityDistantId?: string
	destinationFreely?: boolean
	addressDistant?: string
}

export interface ConfigurationPaymentMethodsResponse {
	id?: number
	companyId?: number
	name?: string
	isActive?: boolean
}
