import { Company, CompanyForm, MySubscription, SubscriptionType } from 'models/Company'
import { ErrorResponse, FetchResponse } from 'models/CustomFetch'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getCompanyService = async (): Promise<Company> => {
	const token = getUserToken()
	if (!token) throw new Error('Token not found')

	try {
		const response = await customFetch<FetchResponse<Company>>(`${endpoints.company}`, {
			token
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const checkCompanyActiveService = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Company>>(`${endpoints.company}/active`, {
			token
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const updateCompanyService = async (company: Partial<CompanyForm>) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Company>>(`${endpoints.company}`, {
			method: 'PATCH',
			token,
			bodyReq: company
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getAllSubscriptionsService = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<SubscriptionType[]>>(
			`${endpoints.subscriptions}`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getMySubscriptionService = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<MySubscription[]>>(
			`${endpoints.mySubscription}`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getLogoCompanyService = async () => {
	const token = getUserToken()
	try {
		const headers: Record<string, string> = {}
		if (token) {
			headers['Token'] = token
		}
		const response = await fetch(`${endpoints.company}/logo`, {
			headers
		})

		if (response.status === 404) {
			return null
		}

		if ('error' in response) throw response

		if (!response.ok) {
			return null
		}

		const blob = await response.blob()
		const objectUrl = URL.createObjectURL(blob)

		return objectUrl
	} catch (err) {
		throw err
	}
}

export const updateLogoCompanyService = async (file: File) => {
	const token = getUserToken()
	const formData = new FormData()
	formData.append('file', file)

	const headers: Record<string, string> = {}
	if (token) {
		headers['Token'] = token
	}

	try {
		const response = await fetch(`${endpoints.company}/logo`, {
			method: 'POST',
			body: formData,
			headers
		})

		if ('error' in response) throw response

		if (!response.ok) {
			const errorData = (await response.json()) as ErrorResponse

			let message = ''
			for (const key in errorData.messages) {
				message += `${errorData.messages[key]}\n`
			}

			return { status: response.status, error: response.status, message }
		}

		return { status: response.status, message: 'Logo updated successfully' }
	} catch (err) {
		throw err
	}
}
