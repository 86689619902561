import Auth from 'components/Auth/Auth'

// Router Components
import PrivateRoute from 'components/elements/PrivateRoute/PrivateRoute'
import PublicRoute from 'components/elements/PublicRoute/PublicRoute'
import ErrorPage from 'components/ErrorPage/ErrorPage'
import ChangePassword from 'components/ForgotPassword/ChangePassword'
import ForgotPassword from 'components/ForgotPassword/ForgotPassword'
import HistoricalPage from 'components/Historical/Historical'
import InactiveCompany from 'components/InactiveCompany/InactiveCompany'
import Legal from 'components/Legal/Legal'

// Auth Routes
import Daily from 'components/Daily/DailyComponent'
import Dashboard from 'components/Dashboard/Dashboard'
import Login from 'components/Login/Login'
import LogOut from 'components/Logout/Logout'
import NotAllowed from 'components/NotAllowed/NotAllowed'
import NotFound from 'components/NotFound/NotFound'
import Notifications from 'components/Notifications/Notifications'
import CreateFirstDriver from 'components/Onboarding/FirstSteps/CreateFirstDriver'
import CreateFirstProvider from 'components/Onboarding/FirstSteps/CreateFirstProvider'
import CreateFirstVehicle from 'components/Onboarding/FirstSteps/CreateFirstVehicle'
import FirstSteps from 'components/Onboarding/FirstSteps/FirstSteps'
import FirstStepsEnd from 'components/Onboarding/FirstSteps/FirstStepsEnd'
import MyCompanyProfile from 'components/Onboarding/FirstSteps/MyCompanyProfile'
import Tour from 'components/Onboarding/Tour/Tour'
import Register from 'components/Register/Register'
import DriverEdit from 'components/Resources/Driver/DriverEdit'
import DriverList from 'components/Resources/Driver/DriverList'
import DriverNew from 'components/Resources/Driver/DriverNew'
import DriverView from 'components/Resources/Driver/DriverView'
import UserTypeDriverRegister from 'components/Resources/Driver/UserTypeDriverRegister'
import FriendAssociation from 'components/Resources/Friend/FriendAssociation'
import FriendList from 'components/Resources/Friend/FriendList'
import FriendsSearch from 'components/Resources/Friend/FriendsSearch'
import ProviderEdit from 'components/Resources/Provider/ProviderEdit'
import ProviderList from 'components/Resources/Provider/ProviderList'
import ProviderNew from 'components/Resources/Provider/ProviderNew'
import ProviderView from 'components/Resources/Provider/ProviderView'
import UserTypeProviderRegister from 'components/Resources/Provider/UserTypeProviderRegister'
import ResourcesList from 'components/Resources/ResourcesList'
import VehicleEdit from 'components/Resources/Vehicle/VehicleEdit'
import VehicleList from 'components/Resources/Vehicle/VehicleList'
import VehicleNew from 'components/Resources/Vehicle/VehicleNew'
import VehicleNewTechnicalDetails from 'components/Resources/Vehicle/VehicleNewTechnicalDetails'
import VehicleView from 'components/Resources/Vehicle/VehicleView'
import Samples from 'components/Samples/Samples'
import Search from 'components/Search/Search'
import Language from 'components/Settings/Language/Language'
import MyAccount from 'components/Settings/MyAccount/MyAccount'
import MyCompany from 'components/Settings/MyAccount/MyCompany/MyCompany'
import MyCompanyEdit from 'components/Settings/MyAccount/MyCompany/MyCompanyEdit'
import MyProfile from 'components/Settings/MyAccount/MyProfile/MyProfile'
import MyProfileEdit from 'components/Settings/MyAccount/MyProfile/MyProfileEdit'
import MyReceiptPdf from 'components/Settings/MyAccount/MySubscription/MyReceiptPdf'
import MyReceipts from 'components/Settings/MyAccount/MySubscription/MyReceipts'
import MySubscription from 'components/Settings/MyAccount/MySubscription/MySubscription'
import Subscriptions from 'components/Settings/MyAccount/MySubscription/Subscriptions'
import SubscriptionSuccess from 'components/Settings/MyAccount/MySubscription/SubscriptionSuccess'
import SettingPage from 'components/Settings/Settings'
import UserDetails from 'components/Settings/User/UserDetails'
import UserEdit from 'components/Settings/User/UserEdit'
import UserList from 'components/Settings/User/UserList'
import UserNew from 'components/Settings/User/UserNew'
import TransferCreate from 'components/Transfer/TransferCreate'
import TransferCreateNoSteps from 'components/Transfer/TransferCreateNoSteps'
import TransferDetails from 'components/Transfer/TransferDetails'
import TransferEdit from 'components/Transfer/TransferEdit'
import Fomento from 'components/Transfer/TransferOptions/Fomento/Fomento'
import FomentoCommunicationPdf from 'components/Transfer/TransferOptions/Fomento/FomentoCommunicationPdf'
import FomentoConfirmationPdf from 'components/Transfer/TransferOptions/Fomento/FomentoConfirmationPdf'
import FomentoForm from 'components/Transfer/TransferOptions/Fomento/FomentoForm'
import TransferPdf from 'components/Transfer/TransferPdf'
import TransferReceive from 'components/Transfer/TransferReceive'
import TransferShare from 'components/Transfer/TransferShare'
import TransferRequests from 'components/TransferRequests/TransferRequests'
import {
	BASE_URL,
	CHANGE_PASSWORD_URL,
	CREATE_URL,
	CREATE_URL_NO_STEPS,
	DAILY_URL,
	DASHBOARD_URL,
	DRIVERS_EDIT_URL,
	DRIVERS_LIST_URL,
	DRIVERS_NEW_URL,
	DRIVERS_VIEW_URL,
	ERROR_URL,
	FORGOT_PASSWORD_URL,
	FORM_URL,
	FRIENDS_ASSOCIATION_URL,
	FRIENDS_LIST_URL,
	FRIENDS_SEARCH_URL,
	HISTORICAL_URL,
	HOME_URL,
	INACTIVE_COMPANY_URL,
	LEGAL_URL,
	LOGIN_URL,
	LOGOUT_URL,
	MY_ACCOUNT_URL,
	MY_COMPANY_EDIT_URL,
	MY_COMPANY_URL,
	MY_PROFILE_EDIT_URL,
	MY_PROFILE_URL,
	NOT_ALLOWED_URL,
	NOT_FOUND_URL,
	NOTIFICATIONS_URL,
	ONBOARDING_COMPANY_PROFILE_URL,
	ONBOARDING_CREATE_FIRST_DRIVER_URL,
	ONBOARDING_CREATE_FIRST_PROVIDER_URL,
	ONBOARDING_CREATE_FIRST_VEHICLE_URL,
	ONBOARDING_END_URL,
	ONBOARDING_URL,
	PROVIDERS_EDIT_URL,
	PROVIDERS_LIST_URL,
	PROVIDERS_NEW_URL,
	PROVIDERS_VIEW_URL,
	RECEIPT_PDF_URL,
	RECEIPTS_URL,
	REGISTER_URL,
	RESOURCES_URL,
	SAMPLES_URL,
	SEARCH_URL,
	SETTINGS_LANGUAGE_URL,
	SETTINGS_URL,
	SUBSCRIPTION_PAYMENT_SUCCESS_URL,
	SUBSCRIPTION_TYPE_URL,
	SUBSCRIPTION_URL,
	SUBSCRIPTIONS_URL,
	TOUR_URL,
	TRANSFER_FOMENTO_COMMUNICATION_PDF_URL,
	TRANSFER_FOMENTO_CONFIRMATION_PDF_URL,
	TRANSFER_FOMENTO_URL,
	TRANSFER_PDF_URL,
	TRANSFER_RECEIVE_URL,
	TRANSFER_REQUESTS_URL,
	TRANSFER_SHARE_URL,
	TRANSFER_URL,
	USER_TYPE_DRIVER_REGISTER_URL,
	USER_TYPE_PROVIDER_REGISTER_URL,
	USERS_EDIT_URL,
	USERS_NEW_URL,
	USERS_URL,
	VEHICLES_EDIT_URL,
	VEHICLES_LIST_URL,
	VEHICLES_NEW_TECHNICAL_DETAILS_URL,
	VEHICLES_NEW_URL,
	VEHICLES_VIEW_URL
} from 'constants/routes'
import { Permissions } from 'models/Configurations'
import { UserRoles } from 'models/User'
import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
const SubscriptionType = lazy(
	() => import('components/Settings/MyAccount/MySubscription/SubscriptionType')
)

export const RoutesContainer = () => {
	return (
		<Routes>
			<Route path={BASE_URL} element={<PublicRoute restricted component={Auth} />} />
			<Route path={LOGIN_URL} element={<PublicRoute restricted component={Login} />} />
			<Route path={LOGOUT_URL} element={<PrivateRoute component={LogOut} />} />
			<Route path={REGISTER_URL} element={<PublicRoute restricted component={Register} />} />
			<Route
				path={FORGOT_PASSWORD_URL}
				element={<PublicRoute restricted component={ForgotPassword} />}
			/>
			<Route
				path={`${CHANGE_PASSWORD_URL}/:token`}
				element={<PublicRoute restricted component={ChangePassword} />}
			/>
			<Route path={LEGAL_URL} element={<PublicRoute restricted component={Legal} />} />

			{/* Common private Routes */}
			<Route path={TOUR_URL} element={<PrivateRoute component={Tour} />} />
			<Route path={SAMPLES_URL} element={<PrivateRoute component={Samples} />} />
			<Route path={ONBOARDING_URL} element={<PrivateRoute component={FirstSteps} />} />
			<Route
				path={ONBOARDING_COMPANY_PROFILE_URL}
				element={<PrivateRoute component={MyCompanyProfile} />}
			/>
			<Route
				path={ONBOARDING_CREATE_FIRST_PROVIDER_URL}
				element={<PrivateRoute component={CreateFirstProvider} />}
			/>
			<Route
				path={ONBOARDING_CREATE_FIRST_DRIVER_URL}
				element={<PrivateRoute component={CreateFirstDriver} />}
			/>
			<Route
				path={ONBOARDING_CREATE_FIRST_VEHICLE_URL}
				element={<PrivateRoute component={CreateFirstVehicle} />}
			/>
			<Route path={ONBOARDING_END_URL} element={<PrivateRoute component={FirstStepsEnd} />} />
			<Route path={HOME_URL} element={<Navigate to={DAILY_URL} />} />
			<Route path={`${DAILY_URL}/:date`} element={<PrivateRoute component={Daily} />} />
			<Route path={DAILY_URL} element={<PrivateRoute component={Daily} />} />
			<Route
				path={`${TRANSFER_URL}${CREATE_URL}/:step`}
				element={
					<PrivateRoute
						component={TransferCreate}
						permissionsRequired={[Permissions.CREATE_TRANSFER]}
					/>
				}
			/>
			<Route
				path={`${TRANSFER_URL}${CREATE_URL}/:step/:transferId`}
				element={
					<PrivateRoute
						component={TransferCreate}
						permissionsRequired={[Permissions.CREATE_TRANSFER]}
					/>
				}
			/>

			<Route
				path={`${TRANSFER_URL}${CREATE_URL_NO_STEPS}`}
				element={
					<PrivateRoute
						component={TransferCreateNoSteps}
						permissionsRequired={[Permissions.CREATE_TRANSFER]}
					/>
				}
			/>
			<Route
				path={`${TRANSFER_URL}${CREATE_URL_NO_STEPS}/:routeTypeName`}
				element={
					<PrivateRoute
						component={TransferCreateNoSteps}
						permissionsRequired={[Permissions.CREATE_TRANSFER]}
					/>
				}
			/>
			<Route
				path={`${TRANSFER_URL}${CREATE_URL_NO_STEPS}/:routeTypeName/:transferId`}
				element={
					<PrivateRoute
						component={TransferCreateNoSteps}
						permissionsRequired={[Permissions.CREATE_TRANSFER]}
					/>
				}
			/>

			<Route
				path={`${TRANSFER_URL}/:transferId`}
				element={<PrivateRoute component={TransferDetails} />}
			/>
			<Route
				path={`${TRANSFER_URL}/:transferId/edit`}
				element={<PrivateRoute component={TransferEdit} />}
			/>
			<Route
				path={`${TRANSFER_PDF_URL}/:transferId`}
				element={<PrivateRoute component={TransferPdf} />}
			/>
			<Route
				path={`${TRANSFER_FOMENTO_URL}/:transferId`}
				element={<PrivateRoute component={Fomento} />}
			/>
			<Route
				path={`${TRANSFER_FOMENTO_URL}/:transferId${FORM_URL}`}
				element={<PrivateRoute component={FomentoForm} />}
			/>
			<Route
				path={`${TRANSFER_FOMENTO_COMMUNICATION_PDF_URL}/:transferId`}
				element={<PrivateRoute component={FomentoCommunicationPdf} />}
			/>
			<Route
				path={`${TRANSFER_FOMENTO_CONFIRMATION_PDF_URL}/:transferId`}
				element={<PrivateRoute component={FomentoConfirmationPdf} />}
			/>
			<Route
				path={`${TRANSFER_SHARE_URL}/:transferId`}
				element={<PrivateRoute component={TransferShare} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${TRANSFER_RECEIVE_URL}/:requestId`}
				element={<PrivateRoute component={TransferReceive} roles={[UserRoles.admin]} />}
			/>
			<Route path={HISTORICAL_URL} element={<PrivateRoute component={HistoricalPage} />} />

			<Route
				path={RESOURCES_URL}
				element={<PrivateRoute component={ResourcesList} roles={[UserRoles.admin]} />}
			/>

			<Route
				path={PROVIDERS_LIST_URL}
				element={<PrivateRoute component={ProviderList} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={PROVIDERS_NEW_URL}
				element={<PrivateRoute component={ProviderNew} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${PROVIDERS_VIEW_URL}/:providerId`}
				element={<PrivateRoute component={ProviderView} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${PROVIDERS_EDIT_URL}/:providerId`}
				element={<PrivateRoute component={ProviderEdit} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${USER_TYPE_PROVIDER_REGISTER_URL}/:providerId`}
				element={<PrivateRoute component={UserTypeProviderRegister} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={DRIVERS_LIST_URL}
				element={<PrivateRoute component={DriverList} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={DRIVERS_NEW_URL}
				element={<PrivateRoute component={DriverNew} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${DRIVERS_VIEW_URL}/:driverId`}
				element={<PrivateRoute component={DriverView} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${DRIVERS_EDIT_URL}/:driverId`}
				element={<PrivateRoute component={DriverEdit} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${USER_TYPE_DRIVER_REGISTER_URL}/:driverId`}
				element={<PrivateRoute component={UserTypeDriverRegister} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={VEHICLES_LIST_URL}
				element={<PrivateRoute component={VehicleList} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={VEHICLES_NEW_URL}
				element={<PrivateRoute component={VehicleNew} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${VEHICLES_NEW_TECHNICAL_DETAILS_URL}/:vehicleId`}
				element={<PrivateRoute component={VehicleNewTechnicalDetails} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${VEHICLES_VIEW_URL}/:vehicleId`}
				element={<PrivateRoute component={VehicleView} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${VEHICLES_EDIT_URL}/:vehicleId`}
				element={<PrivateRoute component={VehicleEdit} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={FRIENDS_LIST_URL}
				element={<PrivateRoute component={FriendList} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${FRIENDS_LIST_URL}/:friendTab/:requestType`}
				element={<PrivateRoute component={FriendList} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={FRIENDS_SEARCH_URL}
				element={<PrivateRoute component={FriendsSearch} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={FRIENDS_ASSOCIATION_URL}
				element={<PrivateRoute component={FriendAssociation} roles={[UserRoles.admin]} />}
			/>

			{/* //SETTINGS_URL */}

			<Route path={SETTINGS_URL} element={<PrivateRoute component={SettingPage} />} />
			<Route path={SETTINGS_LANGUAGE_URL} element={<PrivateRoute component={Language} />} />
			<Route
				path={USERS_URL}
				element={<PrivateRoute component={UserList} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={USERS_NEW_URL}
				element={<PrivateRoute component={UserNew} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${USERS_URL}/:userId`}
				element={<PrivateRoute component={UserDetails} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${USERS_EDIT_URL}/:userId`}
				element={<PrivateRoute component={UserEdit} roles={[UserRoles.admin]} />}
			/>

			<Route path={MY_ACCOUNT_URL} element={<PrivateRoute component={MyAccount} />} />
			<Route
				path={`${SUBSCRIPTION_TYPE_URL}/:planId`}
				element={
					<PrivateRoute
						component={() => (
							<Suspense fallback={<div>Loading...</div>}>
								<SubscriptionType />
							</Suspense>
						)}
					/>
				}
			/>
			<Route
				path={MY_COMPANY_URL}
				element={<PrivateRoute component={MyCompany} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={MY_COMPANY_EDIT_URL}
				element={<PrivateRoute component={MyCompanyEdit} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={SUBSCRIPTION_URL}
				element={<PrivateRoute component={MySubscription} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={SUBSCRIPTIONS_URL}
				element={<PrivateRoute component={Subscriptions} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={SUBSCRIPTION_PAYMENT_SUCCESS_URL}
				element={<PrivateRoute component={SubscriptionSuccess} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={RECEIPTS_URL}
				element={<PrivateRoute component={MyReceipts} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${RECEIPT_PDF_URL}/:invoiceId`}
				element={<PrivateRoute component={MyReceiptPdf} roles={[UserRoles.admin]} />}
			/>
			<Route path={MY_PROFILE_URL} element={<PrivateRoute component={MyProfile} />} />
			<Route path={MY_PROFILE_EDIT_URL} element={<PrivateRoute component={MyProfileEdit} />} />
			<Route
				path={SEARCH_URL}
				element={<PrivateRoute component={Search} roles={[UserRoles.admin]} />}
			/>
			<Route path={NOTIFICATIONS_URL} element={<PrivateRoute component={Notifications} />} />
			<Route path={DASHBOARD_URL} element={<PrivateRoute component={Dashboard} />} />
			<Route
				path={TRANSFER_REQUESTS_URL}
				element={<PrivateRoute component={TransferRequests} roles={[UserRoles.admin]} />}
			/>
			<Route
				path={`${TRANSFER_REQUESTS_URL}/:requestType`}
				element={<PrivateRoute component={TransferRequests} roles={[UserRoles.admin]} />}
			/>
			<Route path={INACTIVE_COMPANY_URL} element={<PrivateRoute component={InactiveCompany} />} />
			<Route path={ERROR_URL} element={<ErrorPage />} />
			<Route path={NOT_FOUND_URL} element={<NotFound />} />
			<Route path={NOT_ALLOWED_URL} element={<NotAllowed />} />
			<Route path='*' element={<Navigate to={NOT_FOUND_URL} replace />} />
		</Routes>
	)
}
