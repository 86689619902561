import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import InputSwitch from 'components/elements/Input/InputSwitch'
import Loading from 'components/elements/Loading/Loading'
import { NOT_ALLOWED_URL, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useConfigurations from 'hooks/useConfigurations'
import useFomento from 'hooks/useFomento'
import { useTransferDetails } from 'hooks/useTransferDetails'
import useTransfers from 'hooks/useTransfers'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { Permissions } from 'models/Configurations'
import { AllStepFormsWithFomentoForm, FomentoInfo, FomentoPost } from 'models/Transfer'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { useConfigStore } from 'stores/useConfigStore'
import { useFomentoStore } from 'stores/useFomentoStore'
import { emptyString } from 'utils/common'
import { dateToSQL } from 'utils/dateUtils'
import * as Yup from 'yup'
import StepAddressesInputs from './StepInputs/StepAddressesInputs'
import StepBookingDateInputs from './StepInputs/StepBookingDateInputs'
import StepClientInputs from './StepInputs/StepClientInputs'
import StepDetailsInputs from './StepInputs/StepDetailsInputs'
import StepExtrasInputs from './StepInputs/StepExtrasInputs'
import StepFomentoInputs from './StepInputs/StepFomentoInputs'
import StepObservationsInputs from './StepInputs/StepObservationsInputs'
import StepPricesInputs from './StepInputs/StepPricesInputs'
import StepRoutesInputs from './StepInputs/StepRoutesInputs'

const TransferEdit: FC = () => {
	const { transferId } = useParams() as { transferId: string }
	const transferIdNumber = parseInt(transferId)
	const navigate = useNavigate()
	const { t } = useTranslation()
	const configState = useConfigStore()
	const { updateTransfer, onRefresh, transfer } = useTransferDetails(transferIdNumber)
	const { onRefresh: onRefreshTransfers } = useTransfers(
		transfer?.date ? new Date(transfer.date) : new Date()
	)
	const { getProvinceCode, getMunicipalityCode, getFomentoInfoById } = useFomento()
	const fomentoState = useFomentoStore()
	const { configurations, isConfigurationActive } = useConfigurations()
	const [loading, setLoading] = useState(false)
	const [isTransferLoaded, setIsTransferLoaded] = useState(false)
	const [isChecked, setIsChecked] = useState(transfer?.statusId === 3)
	const [fomentoInfo, setFomentoInfo] = useState<FomentoInfo | null>(null)
	const [loadingFomentoInfo, setLoadingFomentoInfo] = useState(false)

	const formik = useFormik<AllStepFormsWithFomentoForm>({
		initialValues: {
			statusId: 1,
			externalId: null,
			routeTypeId: null,
			driverId: null,
			providerId: null,
			vehicleId: null,
			passengers: 1,
			date: undefined,
			time: emptyString,
			flight: emptyString,
			originAddress: emptyString,
			destinationAddress: emptyString,
			paymentMethodId: null,
			price: null,
			commission: null,
			otherCommission: null,
			toll: null,
			isPaid: false,
			clientName: emptyString,
			email: emptyString,
			phone: emptyString,
			babySeats: 0,
			boosterSeats: 0,
			maxicosiSeats: 0,
			observations: emptyString,
			privateObservations: emptyString,

			provinceOriginId: null,
			municipalityOriginId: null,
			provinceDestinationId: null,
			municipalityDestinationId: null,
			provinceDistantId: null,
			municipalityDistantId: null,
			destinationFreely: false,
			addressDistant: emptyString,
			finishedAt: undefined
		},
		validationSchema: Yup.object().shape({
			externalId: Yup.string().nullable(),
			routeTypeId: Yup.number().required(
				t('errors.required_m', { field: t('transfer.transfer_type') })
			),
			driverId: Yup.number().required(t('errors.required_m', { field: t('transfer.driver') })),
			providerId: Yup.number().required(t('errors.required_m', { field: t('transfer.provider') })),
			vehicleId: Yup.number().nullable(),
			passengers: Yup.number().required(
				t('errors.required_m', { field: t('transfer.passengers') })
			),
			date: Yup.string().required(t('errors.required_m', { field: t('transfer.date') })),
			time: Yup.string().required(t('errors.required_m', { field: t('transfer.time') })),
			originAddress: Yup.string().required(t('errors.required_m', { field: t('transfer.origin') })),
			destinationAddress: Yup.string().required(
				t('errors.required_m', { field: t('transfer.destination') })
			),
			price: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			commission: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			otherCommission: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			toll: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			isPaid: Yup.boolean(),
			clientName: Yup.string().required(
				t('errors.required_m', { field: t('transfer.clientName') })
			),
			email: Yup.string().email(t('errors.mail_not_valid')).nullable()
		}),
		onSubmit: async (values) => {
			setLoading(true)
			if (!values.providerId || !values.driverId || !values.routeTypeId) return
			try {
				const transfer = {
					...values,
					route: configState.routeTypes.byId[values.routeTypeId].name,
					externalId: values.externalId,
					providerId: values.providerId,
					driverId: values.driverId,
					date: dateToSQL(values.date),
					time: values.time,
					paymentMethodId: values.paymentMethodId,
					price: values.price ?? 0,
					commission: values.commission ?? 0,
					otherCommission: values.otherCommission ?? 0,
					toll: values.toll ?? 0
				}
				await updateTransfer({
					id: transferIdNumber,
					transfer
				})
				const fomentoForm: FomentoPost = {
					provinceOrigin: getProvinceCode(values.provinceOriginId),
					municipalityOrigin: getMunicipalityCode(values.municipalityOriginId),
					provinceDestination: getProvinceCode(values.provinceDestinationId),
					municipalityDestination: getMunicipalityCode(values.municipalityDestinationId),
					provinceDistant: getProvinceCode(values.provinceDistantId),
					municipalityDistant: getMunicipalityCode(values.municipalityDistantId),
					destinationFreely: values.destinationFreely,
					addressDistant: values.addressDistant,
					finishedAt: values.finishedAt ? values.finishedAt : undefined
				}

				if (fomentoInfo?.id) {
					fomentoState.updateFomento(fomentoForm, transferIdNumber)
				} else {
					fomentoState.createFomento(fomentoForm, transferIdNumber)
				}
				onRefresh()
				onRefreshTransfers()
				toast.success(t('toast.success.description'))
				navigate(`${TRANSFER_URL}/${transferId}`)
			} catch (error) {
				console.error(error)
				toast.error(t('toast.error.description'))
			} finally {
				setLoading(false)
			}
		}
	})

	useEffect(() => {
		if (!isConfigurationActive(Permissions.CREATE_TRANSFER)) {
			navigate(NOT_ALLOWED_URL)
		}
	}, [configurations])

	useEffect(() => {
		if (!transfer || isTransferLoaded) return
		formik.setValues({
			routeTypeId: configState.getRouteTypeByName(transfer.route).id,
			statusId: +transfer.statusId,
			externalId: transfer.externalId,
			driverId: transfer.driverId,
			providerId: transfer.providerId,
			vehicleId: transfer.vehicleId,
			passengers: transfer.passengers,
			date: new Date(transfer.date),
			time: transfer.time,
			flight: transfer.flight,
			originAddress: transfer.originAddress,
			destinationAddress: transfer.destinationAddress,
			paymentMethodId: transfer.paymentMethodId,
			price: transfer.price,
			commission: transfer.commission,
			otherCommission: transfer.otherCommission,
			toll: transfer.toll,
			isPaid: transfer.isPaid,
			clientName: transfer.clientName,
			email: transfer.email,
			phone: transfer.phone,
			babySeats: transfer.babySeats,
			boosterSeats: transfer.boosterSeats,
			maxicosiSeats: transfer.maxicosiSeats,
			observations: transfer.observations,
			privateObservations: transfer.privateObservations,

			provinceOriginId: null,
			municipalityOriginId: null,
			provinceDestinationId: null,
			municipalityDestinationId: null,
			provinceDistantId: null,
			municipalityDistantId: null,
			destinationFreely: false,
			addressDistant: emptyString,
			finishedAt: undefined
		})
		setIsChecked(+transfer.statusId === 3)
		setIsTransferLoaded(true)
	}, [transfer, isTransferLoaded])

	useEffect(() => {
		const fetchFomentoInfo = async () => {
			if (transfer) {
				setLoadingFomentoInfo(true)
				const fomentoInfo = await getFomentoInfoById(transfer.id)
				setFomentoInfo(fomentoInfo)
				setLoadingFomentoInfo(false)
			}
		}
		fetchFomentoInfo()
	}, [transfer])

	const handleInputSwitchChange = (value: boolean) => {
		setIsChecked(value)
		formik.setFieldValue('statusId', value ? 3 : formik.initialValues.statusId)
	}

	if (!transfer) {
		return null
	}

	return (
		<FormLayout
			title={t('transfer_details.title')}
			leftButton={<ButtonBack url={`${TRANSFER_URL}/${transferId}`} />}
			footer={
				<ButtonComponent onClick={formik.handleSubmit} fullWidth loading={loading}>
					{t('general.save_changes')}
				</ButtonComponent>
			}>
			<FormSectionLayout title={t('transfer.create_steps.step_route.h1')}>
				<StepRoutesInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_details.h1')}>
				<StepDetailsInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_addresses.h1_v1')}>
				<StepBookingDateInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_addresses.h1_v2')}>
				<StepAddressesInputs
					formik={formik}
					route={configState.routeTypes.byId[formik.values.routeTypeId ?? 0]?.name}
				/>
			</FormSectionLayout>
			{isConfigurationActive(Permissions.NOTIFICATION_FOMENTO) && (
				<FormSectionLayout title={t('transfer.create_steps.step_fomento.h1')} noDivider>
					{loadingFomentoInfo ? (
						<Loading hasText={false} />
					) : (
						<StepFomentoInputs formik={formik} fomentoData={fomentoInfo} />
					)}
				</FormSectionLayout>
			)}
			<FormSectionLayout title={t('transfer.create_steps.step_prices.h1')}>
				<StepPricesInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_client.h1')}>
				<StepClientInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_extras.h1')}>
				<StepExtrasInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_observations.h1')}>
				<StepObservationsInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout>
				<InputSwitch
					label={t('transfer_details.fail_transfer')}
					id='statusId'
					value={isChecked}
					onChange={handleInputSwitchChange}
				/>
			</FormSectionLayout>
		</FormLayout>
	)
}

export default TransferEdit
