import { ImageSquareIcon, UploadSimpleIcon } from 'assets/svgs'
import { useState } from 'react'
import { firstElement } from 'utils/common'
import Loading from '../Loading/Loading'
import './ProfileImageInput.scss'

interface Props {
	onChange?: (file: File) => void
	id?: string
	label?: string
	accept?: string
	isLoading?: boolean
	showIcon?: boolean
	image?: string | null
}

const ProfileImageInput: React.FC<Props> = (props) => {
	const { onChange, id, label, accept, isLoading = false, showIcon = true, image } = props

	const [file, setFile] = useState<File>()

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const newFile = e.target.files[firstElement]
			setFile(newFile)
			if (onChange) onChange(newFile)
		}
	}

	return (
		<div>
			{label && <label className='label'>{label}</label>}
			<div className='profile-image-input-wrapper'>
				<label
					className={`profile-image-input-image-placeholder ${isLoading ? 'loading' : ''}`}
					htmlFor={id || 'profile-image-input'}>
					{isLoading ? (
						<Loading hasText={false} />
					) : (
						<>
							<div className='profile-image-input-image-placeholder-bg'>
								{file ? (
									<img
										className='profile-image-input-image'
										src={URL.createObjectURL(file)}
										alt='Selected file'
									/>
								) : image ? (
									<img className='profile-image-input-image' src={image} alt='Existing profile' />
								) : (
									<ImageSquareIcon />
								)}
							</div>
							{showIcon && (
								<div className='profile-image-input-upload-icon'>
									<UploadSimpleIcon />
								</div>
							)}
						</>
					)}
				</label>

				<input
					id={id || 'profile-image-input'}
					type='file'
					accept={accept || 'image/*'}
					className='profile-image-input'
					onChange={handleChange}
				/>
			</div>
		</div>
	)
}

export default ProfileImageInput
