import StepPricesInputs from 'components/Transfer/StepInputs/StepPricesInputs'
import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useConfigurations from 'hooks/useConfigurations'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { StepPaymentForm, TransferPost } from 'models/Transfer'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTransfersStore } from 'stores/useTransfersStore'
import { emptyString } from 'utils/common'
import * as Yup from 'yup'

const StepPrices = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { configurationTransfer } = useConfigurations()
	const { updateActualTransferCreation, actualTransferCreation } = useTransfersStore()

	const formik = useFormik<StepPaymentForm>({
		initialValues: {
			paymentMethodId: actualTransferCreation.paymentMethodId ?? null,
			price:
				actualTransferCreation.price && actualTransferCreation.price !== 0
					? actualTransferCreation.price
					: null,
			commission:
				actualTransferCreation.commission && actualTransferCreation.commission !== 0
					? actualTransferCreation.commission
					: null,
			otherCommission:
				actualTransferCreation.otherCommission && actualTransferCreation.otherCommission !== 0
					? actualTransferCreation.otherCommission
					: null,
			toll:
				actualTransferCreation.toll && actualTransferCreation.toll !== 0
					? actualTransferCreation.toll
					: null,
			isPaid: actualTransferCreation.isPaid ?? false
		},

		validationSchema: Yup.object().shape({
			price: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			commission: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			otherCommission: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			toll: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			isPaid: Yup.boolean()
		}),
		onSubmit: (values) => {
			const transferPost: Partial<TransferPost> = {
				paymentMethodId: values.paymentMethodId,
				price: values.price ? parseFloat(values.price.toString()) : 0,
				commission: values.commission ? parseFloat(values.commission.toString()) : 0,
				otherCommission: values.otherCommission ? parseFloat(values.otherCommission.toString()) : 0,
				toll: values.toll ? parseFloat(values.toll.toString()) : 0,
				isPaid: values.isPaid
			}
			updateActualTransferCreation(transferPost)
			navigate(`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.CLIENT.toLocaleLowerCase()}`)
		}
	})

	useEffect(() => {
		const config = configurationTransfer && configurationTransfer![actualTransferCreation.route!]
		if (config) {
			formik.setValues({
				...formik.values,
				price: actualTransferCreation.price ?? config.price ?? formik.values.price
			})
		}
	}, [configurationTransfer, actualTransferCreation.route, actualTransferCreation.price])

	return (
		<FormTransferLayout onSubmit={() => formik.handleSubmit()} step={5}>
			<FormSectionLayout title={t('transfer.create_steps.step_prices.h1')} noDivider>
				<StepPricesInputs formik={formik} />
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default StepPrices
