import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import { SUBSCRIPTION_TYPE_URL, SUBSCRIPTION_URL } from 'constants/routes'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { SubscriptionType } from 'models/Company'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getAllSubscriptionsService } from 'services/company'
import SubscriptionCard, { SubscriptionCardMode } from './SubscriptionCard/SubscriptionCard'
import SubscriptionPlanCardContent from './SubscriptionCard/SubscriptionCardContent/SubscriptionPlanCardContent'

const Subscriptions: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([])

	useEffect(() => {
		fetchSubscriptions()
	}, [])

	const fetchSubscriptions = async () => {
		const suscriptions = await getAllSubscriptionsService()
		setSubscriptions(suscriptions)
	}

	return (
		<FormLayout
			title={t('settings.my_account.subscription.subscriptions')}
			leftButton={<ButtonBack url={SUBSCRIPTION_URL} />}>
			<FormSectionLayout>
				{subscriptions.map((subscription) => (
					<SubscriptionCard key={subscription.id} mode={SubscriptionCardMode.LIGHT}>
						<SubscriptionPlanCardContent
							subscription={subscription}
							onSubscribe={() =>
								navigate(`${SUBSCRIPTION_TYPE_URL}/${subscription.plan}`, {
									state: { subscription }
								})
							}
						/>
					</SubscriptionCard>
				))}
			</FormSectionLayout>
		</FormLayout>
	)
}

export default Subscriptions
