import { ArrowLeft } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'
import { previousRoute } from 'utils/common'

interface Props {
	url?: string
}

const ButtonBack: FC<Props> = (props) => {
	const { url } = props

	const { previousRoute: savedRoute } = usePreviousRouteStore()
	const navigate = useNavigate()

	const route = url ?? previousRoute

	const handleClick = () => {
		if (savedRoute) {
			navigate(savedRoute)
		} else {
			navigate(route as string)
		}
	}

	return (
		<ButtonComponent
			variant='only-icon'
			onClick={handleClick}
			icon={<ArrowLeft />}
			dataTest='button-back'
		/>
	)
}

export default ButtonBack
