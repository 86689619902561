import InputButton from 'components/elements/InputButton/InputButton'
import DropdownSelectorModal from 'components/elements/Modal/DropdownSelectorModal'
import useDriver from 'hooks/useDriver'
import useDrivers from 'hooks/useDrivers'
import { useModal } from 'hooks/useModal'
import useUser from 'hooks/useUser'
import { UserRoles } from 'models/User'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUserStore } from 'stores/useUserStore'
import { emptyString, firstElement } from 'utils/common'
import './SelectOptions.scss'

const id = 'select-driver'

interface Props {
	onSelect: (id: number | number[] | undefined) => void
	selectedId?: number | number[] | null
	error?: string
	label: string
	isLabelOptional?: boolean
	isMultiple?: boolean
	setFieldRef?: (field: string) => (ref: HTMLInputElement) => void
}

const SelectDriverOptions: React.FC<Props> = (props) => {
	const {
		onSelect,
		selectedId,
		error,
		label,
		isLabelOptional = false,
		isMultiple = false,
		setFieldRef
	} = props
	const { t } = useTranslation()
	const [modalIsVisible, toggleModalVisibility] = useModal()
	const { drivers, loading, error: driversError } = useDrivers()
	const { hasAssociations } = useDriver()

	const { currentUser } = useUserStore()
	const { me } = useUser(currentUser.id)

	const titleAndPlaceholder = t('form.select_at', { field: t('transfer.driver') })
	const isMultipleAndArray = isMultiple && Array.isArray(selectedId)
	const handleClick = (ids: number[]) => {
		const selected = isMultiple ? ids : ids[firstElement]
		onSelect(selected)
		toggleModalVisibility()
	}
	const handleReset = () => {
		onSelect(undefined)
		toggleModalVisibility()
	}
	const getMultipleValueSelected = () => {
		if (isMultipleAndArray && selectedId.length) {
			const selectedDrivers = selectedId.map(
				(id) => drivers.find((driver) => driver.id === id)?.name
			)
			return selectedDrivers.join(', ')
		}
	}

	const getValueSelected = () => {
		if (selectedId && !Array.isArray(selectedId)) {
			const driver = drivers.find((driver) => driver.id === selectedId)
			return driver?.name
		}
	}

	const itemsDropdownModal = drivers
		.filter((driver) => {
			if (currentUser.userTypeId === UserRoles.driver) {
				return me?.allowedDrivers.includes(driver.id) || driver.isPending
			}

			return driver.isActive || driver.id === selectedId
		})
		.map((driver) => {
			const hasUser = hasAssociations(driver.id)
			return {
				id: driver.id,
				name: driver.name,
				hasUser,
				isSelected: isMultipleAndArray ? selectedId?.includes(driver.id) : undefined,
				isDisabled: !driver.isActive && driver.id !== selectedId
			}
		})

	return (
		<div
			className={`select-option${error ? ' error' : emptyString}`}
			onClick={(e) => e.stopPropagation()}>
			<label htmlFor={id}>
				{label}
				{isLabelOptional && <span className='helper-text'> ({t('general.optional')})</span>}
			</label>
			<InputButton
				id={id}
				type='select'
				text={isMultiple ? getMultipleValueSelected() : getValueSelected()}
				loading={loading}
				placeholder={titleAndPlaceholder}
				onClick={toggleModalVisibility}
				error={error}
				disabled={loading}
				ref={setFieldRef ? setFieldRef('driverId') : undefined}
			/>

			<DropdownSelectorModal
				title={titleAndPlaceholder}
				visible={modalIsVisible}
				items={itemsDropdownModal}
				toggleVisibility={toggleModalVisibility}
				handleClick={handleClick}
				handleReset={handleReset}
				isMultiple={isMultiple}
				selected={selectedId}
			/>

			{error && <span className='input-error-message'>{error}</span>}
			{driversError && <span className='input-error-message'>{driversError}</span>}
		</div>
	)
}

export default SelectDriverOptions
