import { FetchResponse } from 'models/CustomFetch'
import { Invoice } from 'models/Invoice'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getMyInvoicesService = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Invoice[]>>(`${endpoints.myInvoices}`, {
			token
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}
