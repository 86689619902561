import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
	allTransferShareConfigurationsQueryKey,
	allTransfersSharedRequestsQueryKey,
	shareTransferByRequestIdQueryKey,
	transferReceivedByRequestQueryKey
} from 'constants/reactQueryKeys'
import { useMemo } from 'react'
import {
	acceptTransferSharedRequestService,
	cancelTransferSharedRequestService,
	getAllTransferShareConfigurationsService,
	getAllTransferSharedRequestService,
	getTransferReceivedByRequestService,
	getTransferSharedByRequestId,
	rejectTransferSharedRequestService,
	sendTransferSharedRequestService,
	updateAfterConfigurationService,
	updateInitialConfigurationService
} from 'services/transfers'
import { getCompanyId } from 'services/utils'

interface Props {
	requestId?: number
}

const useTransfersShare = ({ requestId }: Props) => {
	const queryClient = useQueryClient()

	const { data: shareTransferByRequestId, isLoading: loadingShareTransferByRequestId } = useQuery({
		queryKey: [shareTransferByRequestIdQueryKey, requestId],
		queryFn: () => getTransferSharedByRequestId(requestId!),
		enabled: !!requestId,
		refetchOnWindowFocus: 'always'
	})

	const { data: transferReceivedByRequest, isLoading: loadingTransferReceivedByRequest } = useQuery(
		{
			queryKey: [transferReceivedByRequestQueryKey, requestId],
			queryFn: () => getTransferReceivedByRequestService(requestId!),
			enabled: !!requestId,
			refetchOnWindowFocus: 'always'
		}
	)

	const { data: allTransfersSharedRequests } = useQuery({
		queryKey: [allTransfersSharedRequestsQueryKey],
		queryFn: () => getAllTransferSharedRequestService(),
		refetchOnWindowFocus: 'always'
	})

	const { data: allTransferShareConfigurations, isLoading: loadingAllTransferShareConfigurations } =
		useQuery({
			queryKey: [allTransferShareConfigurationsQueryKey],
			queryFn: () => getAllTransferShareConfigurationsService(),
			refetchOnWindowFocus: 'always'
		})

	const { mutateAsync: updateTransferShareInitialConfiguration } = useMutation({
		mutationFn: async (ids: number[]) => await updateInitialConfigurationService(ids),
		onSuccess: (data) => {
			return data
		}
	})

	const { mutateAsync: updateTransferShareAfterConfiguration } = useMutation({
		mutationFn: async (ids: number[]) => await updateAfterConfigurationService(ids),
		onSuccess: (data) => {
			return data
		}
	})

	const { mutateAsync: acceptTransferSharedRequest } = useMutation({
		mutationFn: async (requestId: number) => await acceptTransferSharedRequestService(requestId),
		onSuccess: (data) => {
			queryClient.invalidateQueries({
				queryKey: [allTransfersSharedRequestsQueryKey]
			})
			return data
		}
	})

	const { mutateAsync: rejectTransferSharedRequest } = useMutation({
		mutationFn: async (requestId: number) => await rejectTransferSharedRequestService(requestId),
		onSuccess: (data) => {
			queryClient.invalidateQueries({
				queryKey: [allTransfersSharedRequestsQueryKey]
			})
			return data
		}
	})

	const { mutateAsync: sendTransferSharedRequest } = useMutation({
		mutationFn: async ({
			bookingId,
			receivingCompanyId
		}: {
			bookingId: number
			receivingCompanyId: number
		}) => await sendTransferSharedRequestService(bookingId, receivingCompanyId),
		onSuccess: (data) => {
			queryClient.invalidateQueries({
				queryKey: [allTransfersSharedRequestsQueryKey]
			})
			return data
		}
	})

	const { mutateAsync: cancelTransferSharedRequest } = useMutation({
		mutationFn: async (requestId: number) => await cancelTransferSharedRequestService(requestId),
		onSuccess: (data) => {
			queryClient.invalidateQueries({
				queryKey: [allTransfersSharedRequestsQueryKey]
			})
			return data
		}
	})

	const companyId = getCompanyId()

	const filteredRequests = allTransfersSharedRequests || []
	const allSharedRequestsSent = useMemo(
		() => filteredRequests.filter((request) => request.sendingCompanyId === companyId),
		[filteredRequests, companyId]
	)
	const allSharedRequestsReceived = useMemo(
		() => filteredRequests.filter((request) => request.receivingCompanyId === companyId),
		[filteredRequests, companyId]
	)

	return {
		shareTransferByRequestId,
		transferReceivedByRequest,
		allTransfersSharedRequests,
		allSharedRequestsSent,
		allSharedRequestsReceived,
		allTransferShareConfigurations,
		updateTransferShareInitialConfiguration,
		updateTransferShareAfterConfiguration,
		acceptTransferSharedRequest,
		rejectTransferSharedRequest,
		sendTransferSharedRequest,
		cancelTransferSharedRequest,
		loadingShareTransferByRequestId,
		loadingTransferReceivedByRequest,
		loadingAllTransferShareConfigurations
	}
}

export default useTransfersShare
