import ButtonComponent from 'components/elements/Button/Button'
import StatusPage from 'components/elements/StatusPage/StatusPage'
import { DAILY_URL } from 'constants/routes'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from 'stores/useUserStore'

const NotAllowed: FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const userStore = useUserStore()
	const currentUser = userStore.currentUser

	return (
		<StatusPage
			title={t('not_allowed.title')}
			subtitle={t('not_allowed.subtitle')}
			description={t('not_allowed.description')}
			button={
				<ButtonComponent
					variant='text'
					onClick={() => {
						if (currentUser.id && !currentUser.isActive) {
							userStore.logOut()
						} else {
							navigate(DAILY_URL)
						}
					}}>
					{t('not_allowed.action')}
				</ButtonComponent>
			}
		/>
	)
}
export default NotAllowed
