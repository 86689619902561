import {
	CheckIcon,
	CrossIcon,
	MapPinIcon,
	MapPinLineIcon,
	TransferShareIcon,
	UserFilledIcon
} from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import ColorIndicator from 'components/elements/ColorIndicator/ColorIndicator'
import DetailsCard from 'components/elements/DetailsCard/DetailsCard'
import DetailsCardItem from 'components/elements/DetailsCard/DetailsCardItem'
import DetailsCardRow from 'components/elements/DetailsCard/DetailsCardRow'
import HeaderTransferDetails from 'components/elements/HeaderTransferDetails/HeaderTransferDetails'
import MapLink from 'components/elements/MapLink/MapLink'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import { CURRENCY_SYMBOL } from 'constants/common'
import { DAILY_URL, TRANSFER_REQUESTS_URL, TRANSFER_URL } from 'constants/routes'
import useRouteColors from 'hooks/useRouteColors'
import useTransfersShare from 'hooks/useTransfersShare'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { TransferReceived } from 'models/Transfer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'
import { dateToSpanish, timeToSpanish } from 'utils/dateUtils'

const TransferReceive = () => {
	const { requestId } = useParams() as { requestId: string }
	const requestIdNumber = Number(requestId)
	const {
		shareTransferByRequestId,
		transferReceivedByRequest,
		loadingShareTransferByRequestId,
		loadingTransferReceivedByRequest,
		acceptTransferSharedRequest,
		rejectTransferSharedRequest
	} = useTransfersShare({
		requestId: requestIdNumber
	})
	const [transfer, setTransfer] = useState<TransferReceived>()
	const [transferUnavailable, setTransferUnavailable] = useState(false)

	const { t } = useTranslation()
	const navigate = useNavigate()
	const { previousRoute } = usePreviousRouteStore()

	const { getDegradedColor, getRouteColor } = useRouteColors()

	useEffect(() => {
		if (!loadingShareTransferByRequestId && !loadingTransferReceivedByRequest) {
			getData()
		}
	}, [requestId, loadingShareTransferByRequestId, loadingTransferReceivedByRequest])

	const getData = () => {
		const transferRequest = transferReceivedByRequest
		setTransfer(transferRequest)

		const transferShareByRequestId = shareTransferByRequestId!

		if (transferShareByRequestId.rejectedTime) setTransferUnavailable(true)
		else if (transferShareByRequestId.acceptedTime)
			navigate(`${TRANSFER_URL}/${transferShareByRequestId.acceptedBookingId}`)
	}

	const handleAccept = async () => {
		try {
			const transfer = await acceptTransferSharedRequest(requestIdNumber)
			toast.success(t('transfer_requests.accepted'))
			navigate(`${TRANSFER_URL}/${transfer.id}`)
		} catch (error) {
			toast.error(t('transfer_requests.accepted_error'))
		}
	}

	const handleReject = async () => {
		try {
			await rejectTransferSharedRequest(requestIdNumber)
			toast.success(t('transfer_requests.rejected'))
			navigate(previousRoute ?? TRANSFER_REQUESTS_URL)
		} catch (error) {
			toast.error(t('transfer_requests.rejected_error'))
		}
	}

	if (!transfer) {
		return null
	}

	const routeTranslation = t(`transfer.route.${transfer.route}`)

	return (
		<FormLayout
			title={t('transfer.details.h1')}
			leftButton={<ButtonBack url={DAILY_URL} />}
			footer={
				<div className='transfer-details-footer'>
					<ButtonComponent
						type='submit'
						variant='secondary'
						fullWidth
						onClick={handleReject}
						className='transfer-details-receive-action reject'>
						<CrossIcon width={20} height={20} /> {t('general.reject')}
					</ButtonComponent>
					<ButtonComponent
						type='submit'
						variant='primary'
						fullWidth
						onClick={handleAccept}
						className='transfer-details-receive-action accept'>
						<CheckIcon width={20} height={20} />
						{t('general.accept')}
					</ButtonComponent>
				</div>
			}>
			<FormSectionLayout
				title={routeTranslation}
				titleIcon={<ColorIndicator color={getRouteColor(transfer.route)} />}
				noDivider>
				<HeaderTransferDetails
					date={dateToSpanish(new Date(transfer.date))}
					time={timeToSpanish(transfer.time)}
					backgroundColor={getDegradedColor(transfer.route)}
					fomento={null}
				/>
				{(transfer.originAddress || transfer.destinationAddress) && (
					<DetailsCard>
						{transfer.originAddress && (
							<DetailsCardItem title={t('transfer.origin')}>
								<span className='transfer-details-value-with-icon'>
									<MapPinIcon />
									{transfer.originAddress}
									<MapLink address={transfer.originAddress} />
								</span>
							</DetailsCardItem>
						)}
						{transfer.destinationAddress && (
							<DetailsCardItem title={t('transfer.destination')}>
								<span className='transfer-details-value-with-icon'>
									<MapPinLineIcon />
									{transfer.destinationAddress}
									<MapLink address={transfer.destinationAddress} />
								</span>
							</DetailsCardItem>
						)}
					</DetailsCard>
				)}

				<DetailsCard>
					<DetailsCardRow>
						<DetailsCardItem title={t('transfer.passengers')}>
							<span className='transfer-details-value-with-icon'>
								<UserFilledIcon color='#000' />
								{transfer.passengers}
							</span>
						</DetailsCardItem>
					</DetailsCardRow>
				</DetailsCard>

				{transfer.price ? (
					<DetailsCard>
						<DetailsCardRow>
							<DetailsCardItem title={t('transfer.price')}>
								{transfer.price} {CURRENCY_SYMBOL}
							</DetailsCardItem>
						</DetailsCardRow>
					</DetailsCard>
				) : null}
			</FormSectionLayout>
			{transfer.observations ? (
				<FormSectionLayout title={t('transfer.observations')} noDivider>
					<DetailsCard>
						<DetailsCardItem title={t('transfer.observations')}>
							{transfer.observations}
						</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
			) : null}

			<ModalActions
				icon={<TransferShareIcon />}
				title={t('transfer_requests.unavailable_title')}
				description={t('transfer_requests.unavailable_description')}
				onClose={() => navigate(TRANSFER_REQUESTS_URL)}
				isVisible={transferUnavailable}
				primaryButton={{
					text: t('transfer_requests.unavailable_action'),
					onClick: () => navigate(TRANSFER_REQUESTS_URL)
				}}
			/>
		</FormLayout>
	)
}

export default TransferReceive
