import { ENVIRONMENT } from 'constants/environment'
import { type Fomento } from 'models/Fomento'
import { FomentoInfo, FomentoPost } from 'models/Transfer'
import { normalize } from 'normalizr'
import { fomentoSchema } from 'schemas/index'
import {
	cancelFomentoService,
	communicateFomentoService,
	confirmFomentoService,
	createFomentoService,
	getFomentoInfoByBookingIdService,
	updateFomentoService
} from 'services/fomento'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface State {
	actualFomentoCreation: FomentoPost | FomentoInfo
	fomento: {
		byId: { [id: number]: Fomento }
		allIds: number[]
	}
	fomentoInfo: {
		byId: { [id: number]: FomentoInfo }
		allIds: number[]
	}
	getFomentoInfoByBookingId: (bookingId: number) => Promise<Nullable<FomentoInfo>>
	createFomento: (fomento: FomentoPost, transferId: number) => Promise<Fomento>
	updateFomento: (fomento: FomentoPost, transferId: number) => Promise<Fomento>
	fetchFomentoInfoByBookingId: (bookingId: number) => Promise<Nullable<FomentoInfo>>
	setActualFomentoCreation: (fomento: FomentoPost) => void
	isReadyToSaveInFomento: () => boolean
	communicate: (bookingId: number) => Promise<Fomento>
	confirm: (bookingId: number) => Promise<Fomento>
	cancel: (bookingId: number) => Promise<Fomento>
	resetActualFomentoCreation: () => void
	resetFomentoAllFomentoData: () => void
}

export const useFomentoStore = create<State>()(
	devtools(
		(set, get) => ({
			actualFomentoCreation: {} as FomentoPost,
			fomento: {
				byId: {},
				allIds: [] as number[]
			},
			fomentoInfo: {
				byId: {},
				allIds: [] as number[]
			},
			getFomentoInfoByBookingId: async (bookingId) => {
				const fomentoInfoId = get().fomentoInfo.allIds.find((id) => id === bookingId)
				if (fomentoInfoId) return get().fomentoInfo.byId[fomentoInfoId]
				const fomentoInfo = await getFomentoInfoByBookingIdService(bookingId)
				if (!fomentoInfo) return null
				set((state) => ({
					fomentoInfo: {
						...state.fomentoInfo,
						byId: {
							...state.fomentoInfo.byId,
							[fomentoInfo.id]: fomentoInfo
						},
						allIds: [...state.fomentoInfo.allIds, fomentoInfo.id]
					}
				}))
				return fomentoInfo
			},
			createFomento: async (fomento, transferId) => {
				const createdFomento = await createFomentoService(fomento, transferId)
				set((state) => ({
					fomento: {
						...state.fomento,
						byId: {
							...state.fomento.byId,
							[createdFomento.id]: createdFomento
						},
						allIds: [...state.fomento.allIds, createdFomento.id]
					}
				}))
				return createdFomento
			},
			updateFomento: async (fomento, transferId) => {
				const updatedFomento = await updateFomentoService(fomento, transferId)
				set((state) => ({
					fomento: {
						...state.fomento,
						byId: {
							...state.fomento.byId,
							[updatedFomento.id]: updatedFomento
						},
						allIds: [...state.fomento.allIds, updatedFomento.id]
					}
				}))
				return updatedFomento
			},
			fetchFomentoInfoByBookingId: async (bookingId) => {
				const fomentoInfo = await getFomentoInfoByBookingIdService(bookingId)
				if (!fomentoInfo) return null
				set((state) => ({
					fomentoInfo: {
						...state.fomentoInfo,
						byId: {
							...state.fomentoInfo.byId,
							[fomentoInfo.id]: fomentoInfo
						},
						allIds: [...state.fomentoInfo.allIds, fomentoInfo.id]
					}
				}))
				return fomentoInfo
			},
			setActualFomentoCreation: (fomento) => {
				set(
					(state) => ({
						actualFomentoCreation: {
							...state.actualFomentoCreation,
							...fomento
						}
					}),
					false,
					'UPDATE_ACTUAL_FOMENTO_CREATION'
				)
			},
			isReadyToSaveInFomento: () => {
				const actualFomentoCreation = get().actualFomentoCreation
				const { provinceOrigin, municipalityOrigin } = actualFomentoCreation

				if (!provinceOrigin || !municipalityOrigin) return false

				return provinceOrigin?.length > 0 && municipalityOrigin?.length > 0
			},
			communicate: async (bookingId) => {
				const communicatedFomento = await communicateFomentoService(bookingId)
				const normalizedData = normalize(communicatedFomento, fomentoSchema)
				set(
					(state) => ({
						fomento: {
							...state.fomento,
							byId: {
								...state.fomento.byId,
								...normalizedData.entities.fomento
							},
							allIds: [...new Set([...state.fomento.allIds, normalizedData.result])]
						}
					}),
					false,
					'COMMUNICATE_FOMENTO'
				)
				return communicatedFomento
			},
			confirm: async (bookingId) => {
				const confirmedFomento = await confirmFomentoService(bookingId)
				const normalizedData = normalize(confirmedFomento, fomentoSchema)

				set(
					(state) => ({
						fomento: {
							byId: {
								...state.fomento.byId,
								...normalizedData.entities.fomento
							},
							allIds: [...new Set([...state.fomento.allIds, normalizedData.result])]
						}
					}),
					false,
					'CONFIRM_FOMENTO'
				)
				return confirmedFomento
			},
			cancel: async (bookingId) => {
				const canceledFomento = await cancelFomentoService(bookingId)
				const normalizedData = normalize(canceledFomento, fomentoSchema)

				set(
					(state) => ({
						fomento: {
							byId: {
								...state.fomento.byId,
								...normalizedData.entities.fomento
							},
							allIds: [...new Set([...state.fomento.allIds, normalizedData.result])]
						}
					}),
					false,
					'CANCEL_FOMENTO'
				)
				return canceledFomento
			},
			resetActualFomentoCreation: () => {
				set(
					{
						actualFomentoCreation: {} as FomentoPost
					},
					false,
					'RESET_ACTUAL_FOMENTO_CREATION'
				)
			},
			resetFomentoAllFomentoData: () => {
				set({
					actualFomentoCreation: {} as FomentoPost,
					fomento: {
						byId: {},
						allIds: []
					},
					fomentoInfo: {
						byId: {},
						allIds: []
					}
				})
			}
		}),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'fomento store'
		}
	)
)
