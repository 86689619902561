import { RoadHorizon } from 'assets/svgs'
import { FC } from 'react'
import './NoTransfers.scss'

interface Props {
	messageKey: string
}

const NoTransfers: FC<Props> = (props) => {
	const { messageKey } = props

	return (
		<div className='no-transfers-day'>
			<div className='icon-road'>
				<RoadHorizon />
			</div>
			<p>{messageKey}</p>
		</div>
	)
}

export default NoTransfers
