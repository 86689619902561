import Loading from 'components/elements/Loading/Loading'
import { useGlobalSearch } from 'hooks/useGlobalSearch'
import { debounce } from 'lodash'
import { SearchState, SearchTags } from 'models/Search'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './Search.scss'
import FilterTag from './SearchFilterTag'
import SearchHeader from './SearchHeader'
import SearchRecentSearches from './SearchRecentSearches'
import SearchResults from './SearchResults'

const Search = () => {
	const { t } = useTranslation()
	const {
		results,
		loading,
		loadingRecentSearches,
		recentSearches,
		executeSearch,
		addRecentSearch,
		cleanRecentSearches
	} = useGlobalSearch()

	const [search, setSearch] = useState<SearchState>({
		query: '',
		tag: undefined
	})

	const debouncedSearch = useRef(
		debounce((searchObject: SearchState) => {
			executeSearch(searchObject)
		}, 300)
	).current

	useEffect(() => {
		return () => {
			debouncedSearch.cancel()
		}
	}, [debouncedSearch])

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		setSearch((prev) => ({ ...prev, query: value }))
		if (value.trim() !== '') {
			debouncedSearch({ query: value, tag: search.tag })
		}
	}

	const handleTagSelection = (tag: SearchTags) => {
		const newTag = tag === search.tag ? undefined : tag
		setSearch((prev) => ({ ...prev, tag: newTag }))
		if (search.query.trim() !== '') {
			debouncedSearch({ query: search.query, tag: newTag })
		}
	}

	const handleRecentSearchClick = (recentSearch: SearchState) => {
		setSearch(recentSearch)
		executeSearch({ query: recentSearch.query, tag: recentSearch.tag })
		addRecentSearch(recentSearch)
	}

	return (
		<>
			<SearchHeader query={search.query} onInputChange={handleInputChange} />
			<div className='tag-container'>
				{Object.keys(SearchTags).map((key) => {
					const tag = SearchTags[key as keyof typeof SearchTags]
					return (
						<FilterTag
							key={tag}
							activeTag={search.tag}
							tag={tag}
							label={t(`search.tags.${tag}`)}
							onClick={() => handleTagSelection(tag)}
						/>
					)
				})}
			</div>
			<div className='results-container'>
				{loading ? (
					<Loading hasText={false} />
				) : (
					<>
						{results && search.query.length ? (
							<SearchResults results={results} search={search} addRecentSearch={addRecentSearch} />
						) : (
							<>
								<div className='result-container-initial-text'>{t('search.description')}</div>
								<SearchRecentSearches
									loading={loadingRecentSearches}
									recentSearches={recentSearches}
									cleanRecentSearches={cleanRecentSearches}
									handleRecentSearchClick={handleRecentSearchClick}
								/>
							</>
						)}
					</>
				)}
			</div>
		</>
	)
}

export default Search
