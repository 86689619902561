import { Company } from './Company'
import { Driver } from './Driver'
import { Provider } from './Provider'
import { Transfer } from './Transfer'
import { User } from './User'
import { Vehicle } from './Vehicle'

export interface SearchResponse {
	transfers?: Transfer[]
	drivers?: Driver[]
	providers?: Provider[]
	vehicles?: Vehicle[]
}

export interface RecentSearchResponse {
	id: number
	companyId: Company['id']
	userId: User['id']
	query: string
	tag?: SearchTags
	createdAt: string
}

export type SearchState = Pick<RecentSearchResponse, 'query' | 'tag'>

export enum SearchTags {
	transfers = 'transfers',
	drivers = 'drivers',
	providers = 'providers',
	vehicles = 'vehicles'
}
