import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import DropdownSelector from 'components/elements/DropdownSelector/DropdownSelector'
import { TRANSFER_URL } from 'constants/routes'
import useFriends from 'hooks/useFriends'
import { useTransferDetails } from 'hooks/useTransferDetails'
import useTransfersShare from 'hooks/useTransfersShare'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { emptyString } from 'utils/common'
import TransferShareConfiguration from './TransferShareConfiguration'

const TransferShare: FC = () => {
	const { transferId } = useParams() as { transferId: string }
	const transferNumber = parseInt(transferId)
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { pendings } = useTransferDetails(transferNumber)
	const { sendTransferSharedRequest } = useTransfersShare({})
	const { friends } = useFriends()

	const [selectedFriends, setSelectedFriends] = useState<number[]>([])

	const itemsFriends = useMemo(() => {
		return friends.map((friend) => {
			const isSended = pendings.find(
				(pending) => pending.receivingCompanyId === friend.receivingCompanyId
			)

			return {
				id: friend.receivingCompanyId,
				name: friend.receivingCompanyName,
				subtitle: friend.sendingProviderName ?? emptyString,
				isDisabled: !!isSended,
				isSelected: !!isSended
			}
		})
	}, [friends, pendings])

	const handleShareTransfer = async () => {
		const transferPromises = itemsFriends
			.filter((item) => selectedFriends.includes(item.id))
			.filter((item) => !item.isDisabled)
			.map((item) =>
				sendTransferSharedRequest({ bookingId: transferNumber, receivingCompanyId: item.id })
			)

		try {
			await Promise.all(transferPromises)
			navigate(`${TRANSFER_URL}/${transferId}`)
			toast.success(t('transfer_share.success'))
		} catch (error) {
			toast.error(t('transfer_share.error'))
		}
	}

	return (
		<FormLayout
			title={t('transfer_share.title')}
			leftButton={<ButtonBack url={`${TRANSFER_URL}/${transferId}`} />}
			footer={
				<ButtonComponent onClick={handleShareTransfer} fullWidth>
					{t('transfer_share.send')}
				</ButtonComponent>
			}>
			<TransferShareConfiguration />
			<DropdownSelector
				items={itemsFriends}
				handleClick={console.log}
				isMultiple
				description={t('transfer_share.friends_selector.description')}
				subtitle={t('transfer_share.friends_selector.title')}
				selectedItems={selectedFriends}
				setSelectedItems={setSelectedFriends}
			/>
		</FormLayout>
	)
}

export default TransferShare
