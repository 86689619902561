import { Transfer } from 'models/Transfer'
import { useEffect } from 'react'
import { getFomentoInfoByBookingIdService } from 'services/fomento'
import { useConfigStore } from 'stores/useConfigStore'
import { dateToSQL, nextDateSqlFormat } from 'utils/dateUtils'

const useFomento = () => {
	const configState = useConfigStore()

	const getProvinceCode = (provinceId: number | null) => {
		if (!provinceId) return null
		return configState.provinces.byId[provinceId]?.code
	}

	const getMunicipalityCode = (municipalityId: number | null) => {
		if (!municipalityId) return null
		return configState.municipalities.byId[municipalityId]?.municipalityCode
	}

	const getProvinceByCodeHelper = (provinceCode: string | null) => {
		if (!provinceCode) return null
		return configState.getProvinceByCode(provinceCode)?.id
	}

	const getMunicipalityByProvinceCodeAndMunicipalityCodeHelper = (
		provinceCode: string | null,
		municipalityCode: string | null
	) => {
		if (!provinceCode || !municipalityCode) return null
		return configState.getMunicipalityByProvinceCodeAndMunicipalityCode(
			provinceCode,
			municipalityCode
		)?.id
	}

	const getFomentoInfoById = async (transferId: number) => {
		return await getFomentoInfoByBookingIdService(transferId)
	}

	const useSetFinishedAt = (
		{ date, time }: { date: Transfer['date'] | Date; time: Transfer['time'] },
		setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void
	) => {
		useEffect(() => {
			if (date && time) {
				const transferDate = new Date(date)
				const [hour] = time.split(':').map(Number)
				if (hour < 23) {
					const formattedDate = dateToSQL(transferDate)
					setFieldValue('finishedAt', formattedDate)
				} else {
					const nextFormattedDate = nextDateSqlFormat(transferDate)
					setFieldValue('finishedAt', nextFormattedDate)
				}
			}
		}, [date, time, setFieldValue])
	}

	return {
		getProvinceCode,
		getMunicipalityCode,
		getProvinceByCodeHelper,
		getMunicipalityByProvinceCodeAndMunicipalityCodeHelper,
		getFomentoInfoById,
		useSetFinishedAt
	}
}

export default useFomento
